import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { useToast } from "../../../utils/ToastContext";
import {
  CardsContainer,
  DashboardContainer,
  HeaderBox,
  MainBox,
  RobotBox,
  RobotHeading,
  RobotSubHeading,
  DashboardMainContainer,
  GreetingText
} from "../../../components/Dashboard/DashboardStyles";
import { LoaderContainer } from "../../../Common/CommonStyles";
import { API } from "../../../global";
import Loader from "../../../Reusable/Loader/Loader";
import Activity from "./Activity";
import CatchUp from "./CatchUp";
import { useSelector } from "react-redux";

const SdrDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    user: null,
    catchUpData: null,
    activityData: [],
    completionData: [],
  });
  const [greeting, setGreeting] = useState("");


  const { firstName, lastName } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const { showToast } = useToast();

  useEffect(() => {
    // Set the initial greeting
    updateGreeting();

    // Optional: Update greeting every minute to handle time changes
    const interval = setInterval(() => {
      updateGreeting();
    }, 60000); // Updates every minute

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);

  const updateGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) {
      setGreeting("Good Morning");
    } else if (hour < 18) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    setLoading(true);
    fetch(`${API}/sdr-dashboard/get-data`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          const { user, activities, catchUp } = response.result;

          const catchUpData = catchUp;

          const activityData = [
            {
              count: activities.emails.count,
              type: "Emails",
              description: "to be sent today",
              buttonText: activities.emails.action,
            },
            {
              count: activities.linkedinMessages.count,
              type: "LinkedIn",
              description: "messages to be sent today",
              buttonText: activities.linkedinMessages.action,
            },
            {
              count: activities.calls.count,
              type: "Calls",
              description: "to be made today",
              buttonText: activities.calls.action,
            },
          ];

          const completionData = [
            {
              label: "Emails",
              data: [
                { name: "Completed", value: catchUp.activityCompletion.emails.completed },
                { name: "Pending", value: catchUp.activityCompletion.emails.pending },
              ],
            },
            {
              label: "Calls",
              data: [
                { name: "Completed", value: catchUp.activityCompletion.calls.completed },
                { name: "Pending", value: catchUp.activityCompletion.calls.pending },
              ],
            },
            {
              label: "LinkedIn",
              data: [
                { name: "Completed", value: catchUp.activityCompletion.linkedin.completed },
                { name: "Pending", value: catchUp.activityCompletion.linkedin.pending },
              ],
            },
          ];
          setDashboardData({ user, activityData, completionData, catchUpData });
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch((err) => {
        setLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <DashboardMainContainer>
          <DashboardContainer>
            <HeaderBox>
              <Typography variant="h6" sx={{ fontSize: "22px" }}>
                Dashboard
              </Typography>
            </HeaderBox>

            <Box sx={{ marginTop: "20px" }}>
              <GreetingText>
                {greeting}, {firstName} {lastName}!
              </GreetingText>
              <Typography variant="body2" sx={{ marginTop: "12px" }}>
                It's been <b>{dashboardData?.user?.lastLoginDaysAgo} days</b> since you last opened your dashboard. Jump back in and tackle those pending tasks!
              </Typography>
            </Box>

            <MainBox>
              <CardsContainer>
                <Activity dashboardData={dashboardData}/>
                <CatchUp dashboardData={dashboardData}/>
              </CardsContainer>

              <RobotBox className="robot-container">
                <Box sx={{ textAlign: "center", marginRight: "30px" }}>
                  <RobotHeading>Spice Up Your Sales Pipeline</RobotHeading>
                  <RobotSubHeading>Powered By AI</RobotSubHeading>
                </Box>
              </RobotBox>
            </MainBox>
          </DashboardContainer>
        </DashboardMainContainer>
      )}
    </>
  );
};

export default SdrDashboard;