import React, { useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";
import { CustomSliderTextBox, DoneButtonStyle } from "../PlanItStyles";

function valuetext(value) {
  return `$${value >= 1_000_000_000 ? (value / 1_000_000_000).toFixed(1) + 'B' : (value / 1_000_000).toFixed(1) + 'M'}`;
}

const minDistance = 10_000_000;

export default function MinimumDistanceSlider({ setRevenueValue }) {
  const [value1, setValue1] = useState([1_000_000, 2_000_000_000]);
  const [minValue, setMinValue] = useState(value1[0]);
  const [maxValue, setMaxValue] = useState(value1[1]);

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
      setMinValue(Math.min(newValue[0], value1[1] - minDistance));
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
      setMaxValue(Math.max(newValue[1], value1[0] + minDistance));
    }
  };

  const formatValueLabel = (value) => `$${value >= 1_000_000_000 ? (value / 1_000_000_000).toFixed(1) + 'B' : (value / 1_000_000).toFixed(1) + 'M'}`;
  
  const doneValue = () => {
    setRevenueValue(`$${formatValueLabel(minValue)} - $${formatValueLabel(maxValue)}`);
  };

  return (
    <>
      <Box sx={{ width: 300 }}>
        <Slider
          getAriaLabel={() => "Minimum distance"}
          value={value1}
          min={1_000_000}
          max={2_000_000_000}
          step={1_000_000}
          onChange={handleChange1}
          valueLabelDisplay="auto"
          valueLabelFormat={formatValueLabel}
          getAriaValueText={valuetext}
          disableSwap
          sx={{
            width: "272px",
            margin: "14px",
            color: "#009872",
            "& .MuiSlider-thumb": {
              backgroundColor: "#009872",
              "&:hover, &.Mui-focusVisible, &.Mui-active": {
                boxShadow: "0px 0px 0px 8px rgba(76, 175, 80, 0.16)",
              },
            },
            "& .MuiSlider-track": {
              backgroundColor: "#009872",
            },
            "& .MuiSlider-rail": {
              backgroundColor: "green",
            },
            "& .MuiSlider-valueLabel": {
              backgroundColor: "#009872ED",
              color: "#FFFFFF",
              borderRadius: "4px",
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            justifyContent: "center",
            mb: 2,
          }}
        >
          <CustomSliderTextBox
            variant="outlined"
            type="text"
            value={`${formatValueLabel(minValue)}`}
            onChange={(e) =>
              setMinValue(Number(e.target.value.replace(/\D/g, "")))
            }
            sx={{ width: "120px" }}
            InputProps={{
              sx: { height: "30px", padding: "0" },
            }}
          />

          <Typography variant="h6" component="span" sx={{ lineHeight: 1 }}>
            -
          </Typography>

          <CustomSliderTextBox
            variant="outlined"
            type="text"
            value={`${formatValueLabel(maxValue)}`}
            onChange={(e) =>
              setMaxValue(Number(e.target.value.replace(/\D/g, "")))
            }
            sx={{ width: "120px" }}
            InputProps={{
              sx: { height: "30px", padding: "0" },
            }}
          />
        </Box>
      </Box>
      <Typography sx={{ color: "#707070", fontSize: "16px" }}>
        Please click on done after if you agree. You are free to adjust the
        slider.
      </Typography>

      <DoneButtonStyle onClick={doneValue} variant="contained">Done</DoneButtonStyle>
    </>
  );
}
