import React, { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  StartBtn,
  Title,
  TitleBox,
} from "../../components/Dashboard/DashboardStyles";
import { close, greenArrow, question, redArrow } from "../../Constants/Constant";
import TinyAreaChart from "../AreaChat/AreaChart";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  CommonCardBox,
  CountHeading,
  Countimg,
  CountSub,
  SubHeadingCount,
} from "./CommonCardStyles";

const CommonCard = ({ content, width, padding, border }) => {
  const [open, setOpen] = useState(false);

  const handleCloseTooltip = () => {
    setOpen(false);
  };

  const handleOpenTooltip = () => {
    setOpen(true);
  };

  const TitleTooltip = ({ onClose }) => {
    return (
      <div style={{ color: "#9CA4BA" ,textAlign:"right"}}>
        <IconButton sx={{p:0,padding:"5px 0"}} onClick={onClose}><img src={close} style={{width:"8px"}} alt=""/></IconButton>
        <div style={{textAlign:"left"}}>
        The overall revenue opportunity available if 100% market share is achieved.
        </div>
      </div>
    );
  };

  return (
    <CommonCardBox width={width} padding={padding} border={border}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <TitleBox>
            <Title>{content.title}</Title>
            {content.button ? (
                   <Tooltip
                   open={open}
                   onClose={handleCloseTooltip}
                   onOpen={handleOpenTooltip}
                   title={<TitleTooltip onClose={handleCloseTooltip} />}
                   placement="right"
                 >
                   <img
                     src={question}
                     alt=""
                     style={{ width: "14px", height: "14px" }}
                   />
                 </Tooltip>
            ) : (
              ""
            )}
          </TitleBox>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <Box sx={{ mt: 2, display: "flex", alignItems: "end" }}>
              <CountHeading variant="div">{content.count}</CountHeading>
              <CountSub variant="div" color={content.raise}>
                <Countimg src={content.raise ? greenArrow : redArrow} alt="" />{" "}
                <span>{content.sub}%</span>{" "}
              </CountSub>
            </Box>
            <SubHeadingCount>Compared to last week</SubHeadingCount>
          </Box>
        </Box>
        <Box sx={{ width: "152px", height: "123px" }}>
          <TinyAreaChart
            stopcolor1={content.stopcolor1}
            stroke={content.stroke}
            gradientId={`gradient-${content.id}`}
          />
        </Box>
      </Box>
      {content.button ? (
        <StartBtn sx={{ marginBottom: "0px", marginTop: "10px" }}>
          Continue From Last Step <ArrowForwardIcon sx={{ fontSize: "15px" }} />
        </StartBtn>
      ) : (
        ""
      )}
    </CommonCardBox>
  );
};

export default CommonCard;