import React from "react";

import { Box, IconButton, Typography } from "@mui/material";

import {
  CalendarBox,
  CardBox,
  CardsContainer,
  Computerimg,
  ContentBox,
  CustomBox,
  DashboardContainer,
  HeaderBox,
  Heading,
  MainBox,
  RobotBox,
  RobotHeading,
  Robotimg,
  RobotSubHeading,
  StartBtn,
  SubHeading,
  Title,
  TitleBox,
  StartBtnGrey
} from "./DashboardStyles";
import { calendar, computer, robot } from "../../Constants/Constant";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

const cardContent = [
  {
    title: "Action It",
    heading: "Hit The Ground Running !",
    subHeading:
      "The Action It phase is where the magic happens. SDRs take the well-crafted strategy and put it into action with confidence. Armed with real-time insights, personalized scripts for emails, LinkedIn, and calls, and detailed prospect research, SDRs engage effectively and drive positive responses. This phase turns strategy into impactful action, making every outreach effort count.",
    disableStart: true,
  },
  {
    title: "Optimize It",
    heading: "Optimize To Maximize !",
    subHeading:
      "In the Optimize It phase, you keep getting better. All activities are tracked and analyzed through comprehensive dashboards. Piqual AI provides actionable insights to continuously refine and enhance your sales strategy and outreach campaigns. This phase ensures you’re always improving, staying ahead of the curve, and maximizing your sales potential.",
    disableStart: true,
  },
];

const NewUserDashboard = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ height: "100%", display: "flex" }}>
      <DashboardContainer>
        <HeaderBox>
          <Typography variant="h6" sx={{ fontSize: "22px" }}>
            Dashboard
          </Typography>
          <CalendarBox>
            <IconButton sx={{ p: 0 }}>
              <img src={calendar} alt="calendar" style={{ width: "13px" }} />
            </IconButton>
            <Box>This Week</Box>
          </CalendarBox>
        </HeaderBox>

        <MainBox>
          <CardsContainer>
            <CardBox
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <Box>
                <TitleBox>
                  <Title>Plan it</Title>
                  {/* <Tooltip
                    open={open}
                    onClose={handleCloseTooltip}
                    onOpen={handleOpenTooltip}
                    title={<TitleTooltip onClose={handleCloseTooltip} />}
                    placement="right"
                  >
                    <img
                      src={question}
                      alt=""
                      style={{ width: "14px", height: "14px" }}
                    />
                  </Tooltip> */}
                </TitleBox>
                <ContentBox>
                  <Heading variant="div">Let's Get The Plan Rolling!</Heading>
                  <SubHeading variant="div">
                    In the Plan It phase, you’re setting the stage for success.
                    Sales leaders use Piqual AI’s powerful insights to craft a winning go-to-market strategy.
                    Our AI helps you build a targeted database of companies and contacts with high buying intent, perfectly aligned with your offerings.
                    This phase is all about laying a solid foundation, ensuring every move you make is backed by smart, data-driven decisions.
                  </SubHeading>
                </ContentBox>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <StartBtn onClick={() => navigate("/piqual-ai/plan-it")}>
                  Get Started <ArrowForwardIcon sx={{ fontSize: "24px" }} />
                </StartBtn>

                <Computerimg src={computer} alt="computer" />
              </Box>
            </CardBox>

            <CustomBox>
              {cardContent.map((content, i) => (
                <CardBox
                  key={i}
                  sx={{
                    border: "1px solid #9CA4BA66",
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <TitleBox>
                      <Title>{content.title}</Title>
                    </TitleBox>
                    <ContentBox>
                      <Heading variant="div">{content.heading}</Heading>
                      <SubHeading variant="div">
                        {content.subHeading}
                      </SubHeading>
                    </ContentBox>
                  </Box>

                  <Box sx={{ padding: "9px 0 0 0 " }}>
                    <StartBtnGrey disabled="true">
                      Get Started <ArrowForwardIcon sx={{ fontSize: "15px" }} />
                    </StartBtnGrey>
                  </Box>
                </CardBox>
              ))}
            </CustomBox>
          </CardsContainer>
          <RobotBox className="robot-container">
            <Box sx={{ textAlign: "center", marginRight: "30px" }}>
              <RobotHeading>Spice Up Your Sales Pipeline</RobotHeading>
              <RobotSubHeading>Powered By AI</RobotSubHeading>
            </Box>
            <Robotimg src={robot} alt="robot" />
          </RobotBox>
        </MainBox>
      </DashboardContainer>
    </Box>
  );
};

export default NewUserDashboard;