import { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";

import Sidebar from "../../Reusable/Sidebar/Sidebar";
import { LayoutContainer, MainContent } from "./LayoutStyles";
import { useTheme, useMediaQuery } from "@mui/material";

const Layout = () => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [sidebarWidth, setSidebarWidth] = useState("45px");

  useEffect(() => {
    setSidebarWidth(isXsScreen ? "30px" : "45px");
  }, [isXsScreen]);

  return (
    <LayoutContainer>
      <Sidebar
        sidebarWidth={sidebarWidth}
        setSidebarWidth={setSidebarWidth}
      />
      <MainContent sidebarWidth={sidebarWidth}>
        <Outlet />
      </MainContent>
    </LayoutContainer>
  );
};

export default Layout;