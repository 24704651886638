import { useEffect, useState } from "react";

import { Box, useMediaQuery, useTheme } from "@mui/material";

import TabContext from "@mui/lab/TabContext";

import {  OuterBox, TitleBox } from "../PlanItStyles";
import {
  SalesMainBox,
  ProgressBox,
  SummaryBox,
  StatusBox,
  InitialBox,
  LabelBox,
  ContentBox,
  CustomTabContent,
  CustomTab,
  CustomTabsList,
  CustomTabBox,
  ImageContainer,
  LoadingBox,
} from "./SalesPlanStyles";
import StatusProgressbar from "./StatusProgressbar";
import PresenceScore from "./PresenceScore";
import { gtmIcon } from "../../../Constants/Constant";
import { useSelector } from "react-redux";

const SalePlan = () => {
  const [initialBox, setInitialBox] = useState(true);
  // const [loading, setLoading] = useState(true);
  const projectData = useSelector(
    (state) => state.projectDetails.projectDetails
  );
  const metricsDetails = useSelector(
    (state) => state.projectDetails.metricsDetails
  );

  // let loading = false;
  const theme = useTheme();
  const isXLScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [hightLight, setHighLight] = useState(false);

  // const [clientList, setClientList] = useState("");

  const chatData = useSelector((state) => state.companyDetails.aiChatDetails);
  const companyData = chatData;

  // const [prevChatData, setPrevChatData] = useState(chatData);
  const [prevCompanyData, setPrevCompanyData] = useState(companyData);

  const inputData = localStorage.getItem("inputKey");
  // const loadingData = JSON.parse(localStorage.getItem("loading"));
  useEffect(() => {
    if (chatData?.question_category === "about_company") {
      setValue("1");
    }

    if (projectData?.clientele && chatData?.question_category === "clientele") {
      setValue("2");
    }

    if (
      projectData?.target_geographies &&
      chatData?.question_category === "target_geographies"
    ) {
      setValue("3");
    }
    if (
      projectData?.target_industries &&
      chatData?.question_category === "target_industries"
    ) {
      setValue("4");
    }
    if (
      projectData?.prospect_companies?.length !==0  &&
      (chatData?.question_category === "prospect_companies" || chatData?.question_category === 'target_revenue_range' )
    ) {
      setValue("5");
    }

    // setClientList(chatData?.summary?.Clientele);
    // eslint-disable-next-line
  }, [chatData, projectData]);
  useEffect(() => {
    if (inputData === "added") {
      setInitialBox(false);
    }
  }, [inputData]);

  useEffect(() => {
    // Check if chatData has changed
    if (chatData?.category) {
      setHighLight(true); // Highlight or any other action
    }

    // Check if companyData has changed
    if (prevCompanyData !== companyData) {
      setHighLight(true); // Highlight or any other action
    }

    // Update previous values
    // setPrevChatData(chatData);
    setPrevCompanyData(companyData);

    // Optional: Reset highlight after a timeout
    const timeout = setTimeout(() => {
      setHighLight(false);
    }, 3000); // Resets highlight after 3 seconds

    // Cleanup timeout on component unmount
    return () => clearTimeout(timeout);

    // eslint-disable-next-line
  }, [chatData, companyData]);


  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  // Update height on window resize to adapt to zoom changes
  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight); // Dynamically adjust height
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // useEffect(() => {

  //   if (!chatData?.question_category) {
  //     setLoading(false);
  //   }else{
  //     setLoading(loadingData);
  //   }
  // }, [loadingData,chatData]);
  const [value, setValue] = useState("1");
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <>
    <OuterBox bgcolor="#eef4ff"
    viewportHeight={viewportHeight}
      sx={
        hightLight
          ? {
              border: "1px solid #00BB25",
              boxShadow: "inset 0px 0px 9px #00BB25",
            }
          : {}
      }
    >
       <TitleBox>Your Sales Plan So Far</TitleBox>
      {/* <InnerBox> */}
      {initialBox ? (
          <InitialBox>Waiting for Input</InitialBox>
        ) : (
          <SalesMainBox>
            <ProgressBox>
              <StatusBox>
                <ContentBox>
                  {/* {loading ? <Box>Loading...</Box> : <StatusProgressbar status={metricsDetails?.overall_process_status} />} */}
                  <StatusProgressbar
                    status={metricsDetails?.overall_process_status}
                  />
                </ContentBox>
                <LabelBox>Overall Process Status</LabelBox>
              </StatusBox>
              <StatusBox>
                <ContentBox>
                  <Box sx={{ height: "100%", width: "100%" }}>
                    <PresenceScore
                      score={metricsDetails?.online_presence_score}
                    />
                  </Box>
                </ContentBox>
                <LabelBox>Online Presence Score</LabelBox>
              </StatusBox>
              <StatusBox>
                <ContentBox>
                  <Box sx={{ position: "relative" }}>
                    <ImageContainer src={gtmIcon} alt="" />
                    <LoadingBox>
                      {isXLScreen ? "Waiting for the data" : "Loading..."}
                    </LoadingBox>
                  </Box>
                </ContentBox>
                <LabelBox>Go-To-Market Readiness</LabelBox>
              </StatusBox>
            </ProgressBox>

              <>
                {projectData ? (
                  <SummaryBox>
                    <TabContext value={value} sx={{ height: "100%" }}>
                      <Box>
                        <CustomTabsList
                          onChange={(event, newValue) => handleChange(newValue)}
                          aria-label="lab API tabs example"
                          value={value}
                        >
                          {projectData?.about_company && (
                            <CustomTab label="About Company" value="1" />
                          )}
                          {projectData?.clientele && (
                            <CustomTab label="Clientele" value="2" />
                          )}
                          {projectData?.target_geographies && (
                            <CustomTab label="Geography" value="3" />
                          )}
                          {projectData?.target_industries && (
                            <CustomTab label="Industry" value="4" />
                          )}
                          {projectData?.prospect_companies && projectData?.prospect_companies.length !== 0 && (
                          <CustomTab label="Prospect Companies" value="5" />
                        )}
                        </CustomTabsList>
                      </Box>
                      <CustomTabBox>
                        <CustomTabContent value="1">
                          {projectData?.about_company}
                        </CustomTabContent>
                        <CustomTabContent value="2">
                          <ol>
                            {projectData?.clientele?.split(',')?.map((data) => (
                            <li>{data}</li>
                          ))}
                          </ol>
                        </CustomTabContent>
                        <CustomTabContent value="3">
                          <ol>
                            {projectData?.target_geographies}

                          </ol>
                        </CustomTabContent>
                        <CustomTabContent value="4">
                          <ol>
                            {projectData?.target_industries}

                          </ol>
                        </CustomTabContent>
                        <CustomTabContent value="5">
                        <ol>
                          {projectData?.prospect_companies?.map((data) => (
                            <li>{data.company_name}</li>
                          ))}
                        </ol>
                      </CustomTabContent>
                      </CustomTabBox>
                    </TabContext>
                  </SummaryBox>
                ) : (
                  <InitialBox
                    sx={{
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow: " 0px 0px 6px #5593FE1A",
                      border: " 1px solid #5593FE33",
                    }}
                  >
                    Waiting for data...
                  </InitialBox>
                )}
              </>
            {/* )} */}
          </SalesMainBox>
        )}
      {/* </InnerBox> */}
    </OuterBox>
    </>
    // <OuterBox
    //   height="75%"
    //   bgcolor="#eef4ff"
    //   sx={
    //     hightLight
    //       ? {
    //           border: "1px solid #00BB25",
    //           boxShadow: "inset 0px 0px 9px #00BB25",
    //         }
    //       : {}
    //   }
    // >
    //   <TitleBox>Your Sales Plan So Far</TitleBox>
    //   <InnerBox
    //     bgcolor="#5593FE1A"
    //     border="#5593FE33"
    //     height={initialBox ? "83%" : "88%"}
    //     initialbox={initialBox}
    //   >
    //     {initialBox ? (
    //       <InitialBox>Waiting for Input</InitialBox>
    //     ) : (
    //       <SalesMainBox>
    //         <ProgressBox>
    //           <StatusBox>
    //             <ContentBox>
    //               {/* {loading ? <Box>Loading...</Box> : <StatusProgressbar status={metricsDetails?.overall_process_status} />} */}
    //               <StatusProgressbar
    //                 status={metricsDetails?.overall_process_status}
    //               />
    //             </ContentBox>
    //             <LabelBox>Overall Process Status</LabelBox>
    //           </StatusBox>
    //           <StatusBox>
    //             <ContentBox>
    //               <Box sx={{ height: "100%", width: "100%" }}>
    //                 <PresenceScore
    //                   score={metricsDetails?.online_presence_score}
    //                 />
    //               </Box>
    //             </ContentBox>
    //             <LabelBox>Online Presence Score</LabelBox>
    //           </StatusBox>
    //           <StatusBox>
    //             <ContentBox>
    //               <Box sx={{ position: "relative" }}>
    //                 <ImageContainer src={gtmIcon} alt="" />
    //                 <LoadingBox>
    //                   {isXLScreen ? "Waiting for the data" : "Loading..."}
    //                 </LoadingBox>
    //               </Box>
    //             </ContentBox>
    //             <LabelBox>Go-To-Market Readiness</LabelBox>
    //           </StatusBox>
    //         </ProgressBox>

    //           <>
    //             {projectData ? (
    //               <SummaryBox>
    //                 <TabContext value={value} sx={{ height: "100%" }}>
    //                   <Box>
    //                     <CustomTabsList
    //                       onChange={(event, newValue) => handleChange(newValue)}
    //                       aria-label="lab API tabs example"
    //                       value={value}
    //                     >
    //                       {projectData?.about_company && (
    //                         <CustomTab label="About Company" value="1" />
    //                       )}
    //                       {projectData?.clientele && (
    //                         <CustomTab label="Clientele" value="2" />
    //                       )}
    //                       {projectData?.target_geographies && (
    //                         <CustomTab label="Geography" value="3" />
    //                       )}
    //                       {projectData?.target_industries && (
    //                         <CustomTab label="Industry" value="4" />
    //                       )}
    //                       {projectData?.prospect_companies && projectData?.prospect_companies.length !== 0 && (
    //                       <CustomTab label="Prospect Companies" value="5" />
    //                     )}
    //                     </CustomTabsList>
    //                   </Box>
    //                   <CustomTabBox>
    //                     <CustomTabContent value="1">
    //                       {projectData?.about_company}
    //                     </CustomTabContent>
    //                     <CustomTabContent value="2">
    //                       <ol>
    //                         {projectData?.clientele?.split(',')?.map((data) => (
    //                         <li>{data}</li>
    //                       ))}
    //                       </ol>
    //                     </CustomTabContent>
    //                     <CustomTabContent value="3">
    //                       <ol>
    //                         {projectData?.target_geographies}

    //                       </ol>
    //                     </CustomTabContent>
    //                     <CustomTabContent value="4">
    //                       <ol>
    //                         {projectData?.target_industries}

    //                       </ol>
    //                     </CustomTabContent>
    //                     <CustomTabContent value="5">
    //                     <ol>
    //                       {projectData?.prospect_companies?.map((data) => (
    //                         <li>{data.company_name}</li>
    //                       ))}
    //                     </ol>
    //                   </CustomTabContent>
    //                   </CustomTabBox>
    //                 </TabContext>
    //               </SummaryBox>
    //             ) : (
    //               <InitialBox
    //                 sx={{
    //                   background: "#FFFFFF 0% 0% no-repeat padding-box",
    //                   boxShadow: " 0px 0px 6px #5593FE1A",
    //                   border: " 1px solid #5593FE33",
    //                 }}
    //               >
    //                 Waiting for data...
    //               </InitialBox>
    //             )}
    //           </>
    //         {/* )} */}
    //       </SalesMainBox>
    //     )}
    //   </InnerBox>
    // </OuterBox>
  );
};

export default SalePlan;
