import {Box} from '@mui/material';

import { BarContainer, CountContainer, ProgressCount, ProgressStatus } from './SalesPlanStyles';

function LinearProgressWithLabel(props) {
    const { value, progressColor } = props;
   
  

    return (
      <Box sx={{ textAlign: "center" }}>
        <CountContainer>
          <ProgressCount>
            {`${Math.round(value)}%`}
          </ProgressCount>
        </CountContainer>
        <BarContainer >
          <ProgressStatus progressColor={progressColor}
            variant="determinate"
            {...props}
          />
        </BarContainer>
      </Box>
    );
}

const StatusProgressbar = (props) => {
    // const [progress]= useState(chatData.metrics.completed_percentage);

    // const [progress, setProgress] = useState(60);
    // useEffect(() => {
    //     const timer = setInterval(() => {
    //       setProgress((oldProgress) => {
    //         if (oldProgress === 100) {
    //           return 0;
    //         }
    //         const diff = Math.random() * 10;
    //         return Math.min(oldProgress + diff, 100);
    //       });
    //     }, 500);
    
    //     return () => {
    //       clearInterval(timer);
    //     };
    //   }, []);

      // Function to determine color based on progress
      const getProgressColor = (value) => {
        if (value < 20) return "#E76445"; // Color for 0-19%
        if (value < 30) return "#E79645"; // Color for 20-30%
        if (value < 40) return "#E7A645"; // Color for 30-40%
        if (value < 60) return "#E7B645"; // Color for 40-59%
        if (value < 70) return "#A5CF2E"; // Color for 60-70%
        if (value < 80) return "#84C72D"; // Color for 60-79%
        return "#56BB6B"; // Color for 80-100%
    };

    const progressCount = props.status ? props.status    : 0;

    const progressColor = getProgressColor(progressCount);
  return (
    <Box sx={{ width: '100%' }}>
    <LinearProgressWithLabel  value={Math.round(progressCount)}  progressColor={progressColor}/>
  </Box>
  )
}

export default StatusProgressbar