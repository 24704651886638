import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { Box, Divider } from "@mui/material";

import { LoaderContainer, MainContainer } from "../../Common/CommonStyles";
import Header from "../../Reusable/Header/Header";
import NameContainer from "./NameContainer";
import EmailContainer from "./EmailContainer";
import PasswordContainer from "./PasswordContainer";
import ManageAccountContainer from "./ManageAccountContainer";
import { MainAccountContainer } from "./ProfilePageStyles";
import { API } from "../../global";
import Loader from "../../Reusable/Loader/Loader";
import { setUserData } from "../../Redux/Actions/userActions";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { firstName, lastName } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [emailID, setEmailID] = useState("");
  const navigate = useNavigate();
  const [showNewUserDashboard, seShowNewUserPassword] = useState("");
 
  useEffect(() => {
    const chatHistory = localStorage.getItem("chat_history");
    seShowNewUserPassword(chatHistory);
  }, []);

  useEffect(() => {
    fetchDetails();
    //eslint-disable-next-line
  }, []);

  const fetchDetails = () => {
    setLoading(true);
    viewProfileDetails();
  };

  const viewProfileDetails = () => {
    fetch(`${API}/profile/view-profile-details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.success) {        
          dispatch(
            setUserData({
              firstName: res.result.first_name,
              lastName: res.result.last_name,
            })
          );
          setEmailID(res.result.email);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleBackClick = () => {
    if (showNewUserDashboard === "active") {
      navigate("/piqual-ai/dashboard/plan-it");
    } else {
      navigate("/piqual-ai/dashboard");
    }
  };

  return (
    <MainContainer>
      <Header
        title="Account"
        showSettings={false}
        showTabs={false}
        onBackClick={handleBackClick}
      />
      <Divider sx={{ margin: "10px 0" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <LoaderContainer data-testid="loader-container">
            <Loader />
          </LoaderContainer>
        ) : (
          <MainAccountContainer>
            <NameContainer
              firstName={firstName}
              lastName={lastName}
              viewProfileDetails={viewProfileDetails}
            />
            <EmailContainer
              emailID={emailID}
              viewProfileDetails={viewProfileDetails}
            />
            <PasswordContainer viewProfileDetails={viewProfileDetails} />
            <ManageAccountContainer />
          </MainAccountContainer>
        )}
      </Box>
    </MainContainer>
  );
};

export default ProfilePage;