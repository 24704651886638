// src/AuthContext.js
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { jwtDecode } from "jwt-decode";

import PropTypes from "prop-types";

function isTokenExpired(token) {
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000; // current time in seconds
  return decodedToken.exp < currentTime;
}

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [user, setUser] = useState({
    isAuthenticated: false,
    role: "guest", // Default role
  });

  useEffect(() => {
    if (token) {
      const checkTokenExpiration = () => {
        if (token && isTokenExpired(token)) {
          setToken(null);
          localStorage.removeItem("token");
          // history.push('/login');
        }
      };

      checkTokenExpiration();
      const interval = setInterval(checkTokenExpiration, 60000); // check every 60 seconds

      return () => clearInterval(interval);
    }
  }, [token]);

  const login = (newToken,role) => {
    localStorage.setItem("token", newToken);
    setUser({ isAuthenticated: true, role });
    setToken(newToken);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser({ isAuthenticated: false, role: "guest" });
    setToken(null);
  };

  const isAuthenticated = !!token;

  // Memoize the context value
  const value = useMemo(
    () => ({
      token,
      login,
      logout,
      isAuthenticated,
      user
    }),
    // eslint-disable-next-line
    [token, isAuthenticated] // Depend on token and isAuthenticated
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired, // Ensures children is a valid React node
};

AuthContext.propTypes = {
  token: PropTypes.string,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export const useAuth = () => useContext(AuthContext);
