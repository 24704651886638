import {
  SET_CHAT_DETAILS,
  SET_GEOGRAPHY_DETAILS,
  
} from "../Actions/aiChatActions";

const initialState = {
  aiChatDetails: null,
  loading: true,
  geographyData: [],
};

const aiChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT_DETAILS:
      return {
        ...state,
        aiChatDetails: action.payload,
        loading: false,
      };
    case SET_GEOGRAPHY_DETAILS:
      return {
        ...state,
        geographyData: action.payload,
        loading: false,
      };
    
    default:
      return state;
  }
};

export default aiChatReducer;
