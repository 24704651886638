import { combineReducers } from "redux";
import aiChatReducer from '../Redux/Reducer/aiChatReducer';
import userReducer from "../Redux/Reducer/userReducer";
import projectDetailsReducer from "./Reducer/projectDetailsReducer";
import createProjectReducer from "./Reducer/projectReducer";
import sdrUserReducer from "./Reducer/sdrRegistrationReducer";

const rootReducer = combineReducers({
  companyDetails: aiChatReducer,
  user: userReducer,
  projectDetails : projectDetailsReducer,
  startConversation :createProjectReducer,
  sdrData : sdrUserReducer
  // other reducers
});

export default rootReducer;