import { Box, styled } from "@mui/material";

const LayoutContainer = styled(Box)({
  display: "flex",
});

const MainContent = styled(Box)(({ sidebarWidth }) => ({
  padding: "20px",
  width: `calc(100% - ${sidebarWidth})`,
  marginLeft: sidebarWidth,
  transition: "margin-left 0.3s ease",
}));

export { LayoutContainer, MainContent };
