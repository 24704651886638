import { SET_SDR_USER_DATA } from "../Actions/sdrRegisterAction";

const initialState = {
  sdrUserData: null
};

const sdrUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SDR_USER_DATA:
      return {
        ...state,
        sdrUserData: action.payload
      };
    default:
      return state;
  }
};

export default sdrUserReducer;