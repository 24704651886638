import {
  START_CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
} from "../Actions/projectAction";

const initialState = {
  projectData: null,
  loading: false,
  error: null,
};

const createProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_CREATE_PROJECT:
      return { ...state, loading: true, error: null };
    case CREATE_PROJECT_SUCCESS:
      return { ...state, loading: false, projectData: action.payload };
    case CREATE_PROJECT_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default createProjectReducer;
