import {
  GET_PROJECT_DETAILS,
  GET_AI_ENGINE_LOGS,
  GET_METRICS_DETAILS,
  GET_CONVERSATION_DETAILS,
  SET_CURRENT_QUESTION_DATA,
} from "../Actions/aiChatActions";

const initialState = {
  projectDetails: null,
  aiEngineLogs: null,
  metricsDetails: null,
  conversationDetails: null,
  currentQuestionData: null,
};

const projectDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: action.payload,
        loading: false,
      };
    case GET_AI_ENGINE_LOGS:
      return {
        ...state,
        aiEngineLogs: action.payload,
        loading: false,
      };

    case GET_METRICS_DETAILS:
      return {
        ...state,
        metricsDetails: action.payload,
        loading: false,
      };

    case GET_CONVERSATION_DETAILS:
      return {
        ...state,
        conversationDetails: action.payload,
        loading: false,
      };

    case SET_CURRENT_QUESTION_DATA:
      return {
        ...state,
        currentQuestionData: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default projectDetailsReducer;
