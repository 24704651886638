import React, { useState } from "react";

import { Box } from "@mui/material";
import {
  AuthButton,
  FormContainer,
  FormFieldsWrapper,
  FormInput,
} from "../../../Common/CommonStyles";
import { ForgotPasswordLink } from "../Login/LoginStyles";

const ForgotPassword = () => {
  const [CheckEmail, setCheckEmail] = useState(false);
  return (
    <>
      {CheckEmail ? (
         <FormContainer sx={{width:"max-content"}}>
           <Box sx={{ fontSize: "36px", fontWeight: "medium", pt: 1, pb: 2 }}>
           Check Your Email!
          </Box>
          <p>We just sent you an email with a link to reset your password</p>
         </FormContainer>
      ) : (
        <FormContainer>
          <Box sx={{ fontSize: "36px", fontWeight: "medium", pt: 1, pb: 2 }}>
            Forgot Your Password
          </Box>
          <p>Please enter your registered email address</p>
          <FormFieldsWrapper>
            <FormInput
              sx={{ width: "94%" }}
              placeholder="Enter Email Address"
            />
            {/* <ErrorMsg variant="p">{errors?.email?.message}</ErrorMsg> */}
            <AuthButton
              sx={{
                textTransform: "capitalize",
                bgcolor: "#009872ED",
                color: "white",
                "&:hover": {
                  bgcolor: "#088464",
                },
              }}
              type="submit"
              // disabled={loading}
              variant="contained"
              onClick={() => setCheckEmail(true)}
            >
              {/* <span>{loading ? "Loading..." : "Login"}</span> */}
              <span>Request Reset Link</span>
            </AuthButton>
            <ForgotPasswordLink to="/" sx={{ textAlign: "center", mt: 1 }}>
              Back to Login Screen
            </ForgotPasswordLink>
          </FormFieldsWrapper>
        </FormContainer>
      )}
    </>
  );
};

export default ForgotPassword;
