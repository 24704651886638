import React, { useEffect, useRef, useState } from "react";
import {
  InnerBoxAiEngine,
  OuterBoxAiEngine,
  TitleBox,
} from "../PlanItStyles";
import { useSelector } from "react-redux";

const AIEngine = () => {
  const projectData = useSelector((state) => state.projectDetails.aiEngineLogs);
  // const [highlight, setHighlight] = useState(false);

  const aiEngineRef = useRef(null);

  let initialbox = true;

  useEffect(() => {
    if (aiEngineRef.current) {
      aiEngineRef.current.scrollTop = aiEngineRef.current.scrollHeight;
    }

    // setHighLight(true);
  }, [projectData]);

  const [highlightedItems, setHighlightedItems] = useState([]);

  useEffect(() => {
    // Determine the new items by comparing the current list with previously highlighted items
    const newItems = projectData?.filter(
      (item) => !highlightedItems.includes(item)
    ) || [];
    if (newItems.length > 0) {
      setHighlightedItems((prev) => [...prev, ...newItems]);

      // Remove highlight after 3 seconds
      setTimeout(() => {
        setHighlightedItems((prev) =>
          prev.filter((item) => !newItems.includes(item))
        );
      }, 3000);
    }

    // eslint-disable-next-line
  }, [projectData]);


  return (
    <OuterBoxAiEngine sx={{ mt: 2 }} height="25%" bgcolor="#fff2ea">
      <TitleBox>AI Engine</TitleBox>
      <InnerBoxAiEngine
      ref={aiEngineRef}
      sx={{
        fontFamily: "Victor Mono",
        color: "#00A67D",
        whiteSpace: "pre-line",
        overflowY: "auto", // Enable vertical scrolling
      }}
      initialbox={initialbox}
    >
      {projectData?.map((data, index) => (
        <p
          key={index}
          style={{
            color: highlightedItems.includes(data) ? "red" : "#00A67D", // Highlight new items
          }}
        >
          {data?.action + "..."}
        </p>
      ))}
    </InnerBoxAiEngine>
    </OuterBoxAiEngine>
  );
};

export default AIEngine;
