import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import React from 'react';
import {  useMediaQuery, useTheme } from "@mui/material";

const PresenceScore = (props) => {
  const theme = useTheme();
  const isXLScreen = useMediaQuery(theme.breakpoints.up("xl"));

  // const chatData = useSelector((state) => state.companyDetails.aiChatDetails);


  return (
    <div style={{ width: '100%', height: '100%' }}>
     <Gauge
        value={props.score}
        minValue={0}
        maxValue={100}
        animationDuration={1000} // Animation duration in milliseconds
        radius={isXLScreen ?25 :20} // Outer radius of the gauge
        innerRadius={isXLScreen ?20 :25} // Inner radius to create thickness
        text={
            ({ value }) => `${value}%`
         }
        sx={{
          height: '100%', // Take full height of parent
          width: '100%', // Take full width of parent
          fontSize: '12px', 
          fontWeight: 'bold' ,
          [`& .${gaugeClasses.valueArc}`]: {
            fill: '#56BB6B',
          },
        }}
      />
      
    </div>
  );
}

export default PresenceScore;