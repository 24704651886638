import {
  Box,
  Button,
  Chip,
  IconButton,
  styled,
  TextField,
} from "@mui/material";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  // justifyContent: "space-between",
  gap: "10px",
  height: "90vh",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column"
  },
}));

const ChatBox = styled(Box)({
  marginTop: "15px",
  width: "47.5%",
  border: "1px solid #00987233",
  backgroundColor: "#56BB6B0D",

  borderRadius: "8px",

  padding: "20px",

  display: "flex",

  flexDirection: "column",

  justifyContent: "space-between",
});
const MessageContainer = styled(Box)({
  minHeight: "80%",
  minWidth: "90%",
  overflowY: "auto",
  whiteSpace: "pre-wrap", // Preserves whitespace and ensures wrapping
  overflowWrap: "break-word", // Allows text to wrap inside the box
  paddingBottom: "10px",
});

const MessageBox = styled(Box)({
  display: "flex",
  gap: "10px",
  alignItems: "flex-start",
  marginBottom: "10px",
});

const LoadingButton = styled(Button)({
  border: "1px solid #009872",
  width: "max-content",
  padding: "3px",
  color: "#009872",
  backgroundColor: "white",
  fontSize: "11px",
  borderRadius: "3px",
  textTransform: "capitalize",
});

const InputContainer = styled(Box)({
  border: "1px solid #C9C9C9",
  backgroundColor: "white",
  borderRadius: "5px",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

// Define the pulse animation
const pulseAnimation = `
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const MicButton = styled(IconButton)(({ listening }) => ({
  color: "white",
  padding: " 5px",
  backgroundColor: listening ? "#009872" : "",
  "&:hover": {
    backgroundColor: listening ? "#009872" : "",
  },
  animation: listening ? `${pulseAnimation} 1s ease-in-out` : "none",

}));

const PinButton = styled(IconButton)({
  color: "#009872",
  padding: "3px 5px",
});

const SendButton = styled(IconButton)({
  color: "#009872",
  padding: "3px 5px",
});

const TextContainer = styled("input")({
  border: "none",
  width: "85%",
  outline: "none",
  resize: "none",
  height: "15px",
  fontSize: "16px",
  whiteSpace: "pre-wrap", // Preserves whitespace and ensures wrapping
  overflowWrap: "break-word", // Allows text to wrap inside the box
});

const InstructionBox = styled(Box)(({ theme }) => ({
  height: "96%",
  marginTop: "15px",
  border: "1px solid #D0DAE7",
  borderRadius: "5px",
  padding: "5px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.up("xl")]: {
    height: "97%",
  },
}));

const Content = styled(Box)({
  marginInline: "15px",
});

const ContentTitle = styled(Box)({
  fontSize: "24px",
  fontWeight: 500,
});

const UnOrderList = styled("ul")({
  listStyle: "none",
  border: "1px solid #D0DAE7",
  padding: "10px",
  borderRadius: "7px",
});

const ListItems = styled("li")({
  display: "flex",
  alignItems: "center",
  gap: "15px",
});

const ContentNum = styled(Box)({
  backgroundColor: "#D0DAE7",
  width: "18px",
  padding: "9px",
  borderRadius: "50px",
  textAlign: "center",
  fontSize: "14px",
  fontWeight: 500,
});

const Heading = styled(Box)(({ theme }) => ({
  color: "#0D162F",
  fontSize: "14px",
  marginBottom: "7px",
  fontWeight: 500,
  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
  },
}));

const SubHeading = styled(Box)(({ theme }) => ({
  color: "#707070",
  fontSize: "11px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const InputBox = styled(Box)({
  display: "flex",
  width: "100%",
  alignItems: "center",
});

const LabelBox = styled(Box)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "12px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const MessageView = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  color: "#707070",
  lineHeight: "24px",
  fontSize: "12px",
  whiteSpace: "pre-line",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
    lineHeight: "22px",
  },
}));

const SubContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
}));

const SalesPlanContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "15px",
  boxSizing: "border-box",
});

const OuterBox = styled(Box)(({ bgcolor, viewportHeight, theme }) => ({
  backgroundColor: bgcolor,
  width: "100%",
  padding: "0 20px 20px 20px",
  borderRadius: "8px",
  height: `calc(${viewportHeight * 0.7}px)`,
  boxSizing: "border-box",
  [theme.breakpoints.up("xl")]: {
    fontSize: "14px",
    height: "66%",
  },
}));

const OuterBoxAiEngine = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff2ea",
  width: "100%",
  padding: "0 20px 20px 20px",
  borderRadius: "8px",
  height: "25%",
  boxSizing: "border-box",
  [theme.breakpoints.up("xl")]: {
    height: "30%",
  },
}));

const InnerBox = styled(Box)(
  ({ bgcolor, height, border, theme, initialbox }) => ({
    backgroundColor: initialbox ? "white" : "transparent",
    borderRadius: "8px",
    // margin: "0 10px 10px",
    padding: initialbox ? "10px" : "0px",
    boxShadow: initialbox ? `0px 0px 6px ${bgcolor}` : "none",
    border: initialbox ? `1px solid ${border}` : "none",
    height: "70%", // Inner box height relative to the outer box
    overflowY: "auto", // Enable vertical scrolling
    overflowX: "hidden",
    // height: initialbox ? "83%" : "88%",
    fontSize: "12px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "14px",
      height: initialbox ? "73%" : "78%",
    },
  })
);

const InnerBoxAiEngine = styled(Box)(({ theme, initialbox }) => ({
  backgroundColor: initialbox ? "white" : "transparent",
  borderRadius: "8px",
  // margin: "0 10px 10px",
  padding: initialbox ? "10px" : "0px",
  boxShadow: initialbox ? `0px 0px 6px #FF7D341A` : "none",
  border: initialbox ? `1px solid #FF7D3433` : "none",
  height: "55%",
  fontSize: "12px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "14px",
    height: "60%",
  },
}));

const TitleBox = styled(Box)(({ theme, height }) => ({
  fontSize: "14px",
  fontWeight: 500,
  height: height,
  paddingLeft: "10px",
  display: "flex",
  alignItems: "center",
  padding: "10px 0px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
  },
}));
const StyledChip = styled(Chip)({
  borderRadius: "5px",
  margin: "5px",
  padding: "0px",
  height: "30px",
  border: "1px solid #707070;",
  cursor: "pointer",
  backgroundColor: "#ffffff",
  color: "#707070",

  "& .MuiChip-label": {
    padding: "0 5px",
    fontSize: "14px",
  },

  "&:hover": {
    backgroundColor: "#707070 !important",
    borderColor: "#707070",
    color: "#FFFFFF",
  },
});

const StyledChipAdd = styled(Chip)({
  borderRadius: "5px",
  margin: "5px",
  padding: "0px",
  height: "30px",
  border: "1px solid #009872",
  cursor: "pointer",
  backgroundColor: "#ffffff",
  color: "#009872",

  "& .MuiChip-label": {
    padding: "0 5px",
    fontSize: "14px",
  },
  "& .MuiChip-deleteIcon": {
    color: "#009872", // Change delete icon color when hovered
  },
  // Hover styles for the entire Chip
  "&:hover": {
    backgroundColor: "#008463 !important",
    borderColor: "#009872",
    color: "#FFFFFF",

    // Change delete icon color on hover
    "& .MuiChip-deleteIcon": {
      color: "#FFFFFF", // Change delete icon color when hovered
    },
  },
});

const StyledChipGeography = styled(Chip)({
  borderRadius: "5px",
  margin: "5px",
  padding: "0px",
  height: "30px",
  border: "1px solid #009872",
  cursor: "pointer",
  backgroundColor: "#ffffff",
  color: "#009872",

  "& .MuiChip-label": {
    padding: "0 5px",
    fontSize: "14px",
  },
  // "& .MuiChip-deleteIcon": {
  //   color: "#FFFFFF", // Change delete icon color when hovered
  // },
  // Hover styles for the entire Chip
  "&:hover": {
    backgroundColor: "#008463 !important",
    borderColor: "#009872",
    // color: "#FFFFFF",

    // Change delete icon color on hover
    "& .MuiChip-deleteIcon": {
      color: "#FFFFFF", // Change delete icon color when hovered
    },
  },
});

const DoneButtonStyle = styled(Button)({
  height: "30px",
  width: "61px",
  background: "#009872",
  border: "1px solid #009872",
  margin: "5px",
  textTransform: "capitalize",
  // Adding hover styles
  "&:hover": {
    background: "#008463",
    border: "1px solid #008463", // Change border color on hover
    color: "#FFFFFF", // Change text color on hover
  },
});

const CustomTextField = styled(TextField)({
  margin: "5px",
  height: "40px",
  background: " #FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 0px 10px #8248D01A",
  borderRadius: "4px",
  border: "1px solid #707070",
  color: "#ABABAB",
  "& .MuiInputBase-root": {
    height: "100%",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px", // Adjust padding as needed
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#707070",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#707070",
    },
  },
});

const CustomSliderTextBox = styled(TextField)({
  width: "120px",
  border: "1px solid #707070",
  borderRadius: "4px",
  color: "#707070",
});

const SuggestionButton = styled(Box)(({ bgColor }) => ({
  background: `${bgColor} 0% 0%  no-repeat padding-box`,
  height: "31px",
  border: "1px solid #009872",
  borderRadius: "4px",
  opacity: 1,
  color: "White",
  textTransform: "capitalize",
  alignContent: "center",
  padding: "0px 10px 0px 10px",
  cursor: "pointer"
}));

export { OuterBox, InnerBox, TitleBox };

export {
  ChatBox,
  MessageContainer,
  MessageBox,
  LoadingButton,
  InputContainer,
  MicButton,
  PinButton,
  SendButton,
  TextContainer,
  InstructionBox,
  Content,
  UnOrderList,
  ContentNum,
  ListItems,
  Heading,
  SubHeading,
  ContentTitle,
  InputBox,
  MainContainer,
  LabelBox,
  MessageView,
  SubContainer,
  SalesPlanContainer,
  StyledChip,
  CustomTextField,
  StyledChipAdd,
  DoneButtonStyle,
  StyledChipGeography,
  CustomSliderTextBox,
  OuterBoxAiEngine,
  InnerBoxAiEngine,
  SuggestionButton,
};
