import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/piqual-ai/dashboard'); // Navigate to the home page
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                p: 4,
            }}
        >
            <Typography variant="h4" sx={{ color: '#0D162F', mb: 2 }}>
                403 - Unauthorized
            </Typography>
            <Typography variant="body1" sx={{ color: '#707070', mb: 4 }}>
                You do not have the necessary permissions to access this page.
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
                
                <Button
                    variant="contained"
                    sx={{backgroundColor:"#009872ED"}}
                    onClick={handleGoHome}
                >
                    Go to Home
                </Button>
            </Box>
        </Container>
    );
};

export default UnauthorizedPage;
