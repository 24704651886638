import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../global";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../utils/ToastContext";
import { setUserData } from "../../../Redux/Actions/userActions";
import { useAuth } from "../AuthContext/AuthContext";

const EULAPage = () => {

  const navigate =useNavigate()
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const { login } = useAuth();

  const userData = useSelector((state) => state?.sdrData?.sdrUserData);
  const [loading,setLoading] = useState(false)
  const agreeEULA=()=>{
    const obj={
      firstName : userData.firstName,
      lastName : userData.lastName,
      password :userData.password,
      user_id : userData.user_id,
      email : userData.email
    }
    fetch(`${API}/auth/register-sdr`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("response",res)
        console.log("res.success",res.success)
        if (res.success) {
          const userData = res.result;
          dispatch(
            setUserData({
              firstName: userData.first_name,
              lastName: userData.last_name,
            })
          );
          localStorage.setItem("token", userData.token);
          localStorage.setItem("sas_url", userData.sas_token);
          localStorage.setItem("userId", userData.user_id);
          localStorage.setItem("role", userData.user_role_key);
          localStorage.setItem("first_name", userData.first_name);
          localStorage.setItem("last_name", userData.last_name);
          localStorage.setItem("chat_history", userData.chat_status);

          login(userData.token);
          if(userData.user_role_key === 'piqai_sdr'){
            navigate("/piqual-ai/sdr-dashboard");
          }else{
            navigate("/piqual-ai/dashboard");
          }
        } else {
          showToast("error", `${res.error.msg}`);
        }
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", "Error occurred please try again");
      });
  }
  return (
    <>
      <Box
        sx={{
          border: "1px solid #9CA4BA66",
          borderRadius: "12px",
          height: "484px",
          width: "100%",
          maxWidth: "680px",
          letterSpacing: "0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          textAlign: "center",
          padding: "16px",
          boxSizing: "border-box",
          overflow: "hidden", // Hide overflowing content
        }}
      >
        <Typography
          variant="h6"
          sx={{
            wordWrap: "break-word",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "24px",
            color: "#009872",
            marginBottom: "16px",
          }}
        >
          End User License Agreement (EULA)
        </Typography>
        <Box
          sx={{
            overflowY: "auto",
            height: "100%",
            width: "100%",
            padding: "0 8px",
            textAlign: "left",
          }}
        >
          <Typography variant="body2" sx={{ lineHeight: "1.6", color: "#000" }}>
            <p>
              This End User License Agreement (the "Agreement") is a legal
              contract between you (referred to as "Candidate" or "User") and
              [Video Interview Platform Company], a company incorporated under
              the laws of [Jurisdiction], with its principal place of business
              at [Address] (referred to as "Company" or "Platform"). This
              Agreement governs your use of the video interview platform
              provided by the Company.
            </p>
            <p>
              By accessing or using the video interview platform, you
              acknowledge that you have read, understood, and agree to be bound
              by the terms and conditions of this Agreement. If you do not agree
              with any part of this Agreement, you must not use the platform.
            </p>
            <strong style={{ color: "#009872" }}>1. Grant of License</strong>
            <p>
              1.1. Subject to your compliance with the terms and conditions of
              this Agreement, the Company grants you a limited, non-exclusive,
              non-transferable, and revocable license to access and use the
              video interview platform solely for the purpose of participating
              in video interviews conducted by potential employers, recruiters,
              or other authorized entities.
            </p>
            {/* Add other sections here */}
            <strong style={{ color: "#009872" }}>
              7. Governing Law and Jurisdiction
            </strong>
            <p>
              7.1. This Agreement shall be governed by and construed in
              accordance with the laws of [Jurisdiction].
            </p>
            <p>
              7.2. Any disputes arising out of or in connection with this
              Agreement shall be subject to the exclusive jurisdiction of the
              courts of [Jurisdiction].
            </p>
            <strong style={{ color: "#009872" }}>8. Miscellaneous</strong>
            <p>
              8.1. This Agreement constitutes the entire agreement between you
              and the Company regarding the subject matter herein and supersedes
              all prior or contemporaneous agreements or understandings, whether
              written or oral.
            </p>
          </Typography>
        </Box>
      </Box>
      <Box sx={{display:"flex",gap:3}}>
        <Button onClick={agreeEULA} sx={{width : "100px",height:"48px",background:"#109D79 0% 0% no-repeat padding-box",borderRadius:"4px",color:"white",textTransform:'capitalize',fontSize:"16px"}}>
            {loading ? "Loading.." : "I Agree"}
        </Button>
        <Button sx={{width : "100px",height:"48px",borderRadius:"4px",color:"#009872",border:"1px solid #109D79",textTransform:'capitalize',fontSize:"16px"}}>
            Cancel
        </Button>
      </Box>
    </>
  );
};

export default EULAPage;
