import AIEngine from './AIEngine/AIEngine'
import { SalesPlanContainer } from "./PlanItStyles";
import SalePlan from './SalesPlan/SalesPlan'

const SalesPlanDetails = ({clientDetails}) => {
  return (
    <SalesPlanContainer>
        <AIEngine/>
        <SalePlan clientDetails={clientDetails} />
    </SalesPlanContainer>
  )
}

export default SalesPlanDetails