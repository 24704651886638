import { Box, styled } from "@mui/material";

const AuthContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  height: "100vh",
  overflowY: "hidden",
  width: "100%",
});

const ImageContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "67%",
  [theme.breakpoints.down("md")]: {
    display: "none", // Hide the container on xs screens
  },
}));

const BackgroundImg = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "fill",
  zIndex: -1,
  position: "absolute",
  top: 0,
  left: 0,
});

const AuthFormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "37%",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "10px",
  margin: "20px",

  [theme.breakpoints.down("md")]: {
    width: "100%", // Hide the container on xs screens
  },

  [theme.breakpoints.up("sm")]: {
    marginTop: "-20px",
  },
}));

const TextContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  gap: "10px",
});

const Heading = styled(Box)({
  fontWeight: "bold",
  color: "white",
  fontSize: "64px",
  textAlign: "center",
});

const SubHeading = styled(Box)({
  textAlign: "center",
  color: "white",
  fontSize: "36px",
  fontWeight: 400,
});

const AuthLogo = styled("img")({
  width: "270px",

  marginTop: "50px",
});

const LogoImg = styled("img")({
  width: "176px",

});

const ImgBox = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  width: "max-content",
  marginLeft: "30px",
  zIndex: -1,
  position: "absolute",
  padding: "26px 42px  14px 42px ",
  borderRadius: "0px 0px 10px 10px",
  verticalAlign: "middle",
}));

export {
  AuthContainer,
  ImageContainer,
  AuthFormContainer,
  TextContainer,
  BackgroundImg,
  Heading,
  SubHeading,
  AuthLogo,
  LogoImg,
  ImgBox,
};
