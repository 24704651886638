import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import {
  CalendarBox,
  ContainerBox,
  DashboardContainer,
  DBStatusBox,
  HeaderBox,
  MainBox,
  OutreachBox,
  StatusBox,
  Title,
} from "./DashboardStyles";
import { calendar } from "../../Constants/Constant";
import CommonCard from "../../Reusable/CommonCard/CommonCard";
import PieChartContainer from "./PieChartContainer";

const DashboardPlanIt = () => {
  const cardContent = [
    { id:1,title: "Plan It", count: 131, sub: 27, raise: true, button: true ,stopcolor1:"#6300D7",stroke:"#6300d7"},
    { id:2,title: "Action It", count: 92, sub: 13, raise: true, button: true ,stopcolor1:"#00A2FF",stroke:"#00a2ff"},
    { id:3,title: "Optimize It", count: 57, sub: 32, raise: false, button: true ,stopcolor1:"#FF3476",stroke:"#ff3476"},
  ];

  const DBContent = [
    {
      id:4,title: "Number of Companies added",
      count: 246,
      sub: 15,
      raise: true,
      button: false,
    stopcolor1:"#1535D1",stroke:"#1535d1"
    },
    {
      id:5,title: "Number of Contacts added",
      count: 168,
      sub: 4,
      raise: false,
      button: false,
       stopcolor1:"#FF6600",stroke:"#ff6600"
    },
  ];

  const OutReachData = [
    {
      id:6,title: "Number of Companies reached out to",
      count: 77,
      sub: 6,
      raise: true,
      button: false,
       stopcolor1:"#1535D1",stroke:"#1535d1"
    },
    {
      id:7,title: "Number of Contacts reached out to",
      count: 81,
      sub: 2,
      raise: false,
      button: false,
      stopcolor1:"#FF6600",stroke:"#ff6600"
    },
  ];
  return (
    <Box sx={{ height: "100%", display: "flex" }}>
      <DashboardContainer>
        <HeaderBox>
          <Typography variant="h6" sx={{ fontSize: "22px" }}>
            Dashboard
          </Typography>
          <CalendarBox>
            <IconButton sx={{ p: 0 }}>
              <img src={calendar} alt="calendar" style={{ width: "13px" }} />
            </IconButton>
            <Box>This Week</Box>
          </CalendarBox>
        </HeaderBox>
        <MainBox sx={{ gap: "40px"}}>
          <ContainerBox sx={{pt:1}}>
            {cardContent.map((content, i) => (
              <CommonCard
                key={i}
                content={content}
                width="100%"
                padding="20px"
                border="#9CA4BA66"
                gradientId={`gradient-${content.id}`} // Pass unique ID here
              />
            ))}
          </ContainerBox>

          <StatusBox>
            <DBStatusBox>
              <Title>Database Status</Title>
              {DBContent.map((content, i) => (
                <CommonCard
                  content={content}
                  width="92%"
                  padding="20px"
                  border="#FF7D3433"
                />
              ))}
            </DBStatusBox>
            <OutreachBox>
              <Title>Outreach Status</Title>
              <Box
                sx={{
                  display: "flex",
                  gap: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    width: "50%",
                  }}
                >
                  {OutReachData.map((content, i) => (
                    <CommonCard
                      content={content}
                      width="92%"
                      padding="20px"
                      border="#5593FE33"
                    />
                  ))}
                </Box>
                <Box
                  sx={{
                    width: "45%",
                    padding: "20px",
                    borderRadius: "4px",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: " 0px 0px 6px #FF7D341A",
                  }}
                >
                  <Title>Number of responses</Title>

                  <Box sx={{ width: "100%", height: "100%" }}>
                    <PieChartContainer />
                  </Box>
                </Box>
              </Box>
            </OutreachBox>
          </StatusBox>
        </MainBox>
      </DashboardContainer>
    </Box>
  );
};

export default DashboardPlanIt;