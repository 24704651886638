import React from 'react';
import { AreaChart, Area,ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 0,
    
  },
  {
    name: 'Page B',
    uv: 1000,
  
  },
  {
    name: 'Page C',
    uv: 2000,
 
  },
  {
    name: 'Page D',
    uv: 2080,
  
  },
  {
    name: 'Page E',
    uv: 2890,
  
  },
  {
    name: 'Page F',
    uv: 3000,
 
  },
  {
    name: 'Page G',
    uv: 4000,

  },
];

const TinyAreaChart = ({ stopcolor1, stroke, gradientId }) => {


  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data} margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
        <defs>
          <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={stopcolor1} stopOpacity={0.2} />
            <stop offset="100%" stopColor={stopcolor1} stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <Area type="monotone" dataKey="uv" stroke={stroke}  strokeWidth='2' fill={`url(#${gradientId})`} />
      </AreaChart>
    </ResponsiveContainer>
  );
};


export default TinyAreaChart;
