import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    typography: {
      fontFamily: "Roboto, Arial, sans-serif",
    },
    bluebutton: {
      main: "#6249EF",
      hover: "#4E3BBC",
      disable: "#C5C5C5",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white', // Change to white background
          boxShadow: '0px 0px 6px #9CA4BA1A',
          border: '1px solid #9CA4BA', // Optional: remove box shadow
        },
      },
    },
  },
});
