import { Box } from "@mui/material";

import Header from "../../Reusable/Header/Header";
import ChatInterface from "./ChatInterface";
// import GetStartedScreen from "./GetStartedScreen";
import { MainContainer, SubContainer } from "./PlanItStyles";
import SalesPlanDetails from "./SalesPlanDetails";
import { useState } from "react";

const PlanIt = () => {
  const [headerData, setHeaderData] = useState('');
  const [clientDetails] = useState(null)
  
  const headingData = (data) => {
    setHeaderData(data)
  }

  return (
    <Box>
      <Header
        headerData={headerData}
        title="Plan"
        showSettings={true}
        showTabs={true}
      />
      <MainContainer>
        <ChatInterface onSendData={headingData} />
        <SubContainer>
          <SalesPlanDetails clientDetails={clientDetails} />
        </SubContainer>
      </MainContainer>
    </Box>
  );
};

export default PlanIt;
