import { Box, styled, Typography } from "@mui/material";

const DashboardContainer = styled(Box)({
  height: "100%",
  width: "100%",
  // borderRight: "1px solid #9CA4BA66",
  // paddingRight: "15px",
  overflow: "hidden"
});

const MainBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  paddingTop: "20px",
});

const CardsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "20px",
  width: "100%",
  [theme.breakpoints.up("xs")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const CardBox = styled(Box)(({ theme }) => ({
  border: "2px solid #009872",
  borderRadius: "8px",
  padding: "20px",
  // [theme.breakpoints.up("xs")]: {
  //   width: "99.5%",
  //   height: "100%",
  // },
}));

const ContentBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  // padding: "0px 10px",
  padding: "10px 0",
  justifyContent: "space-between",
});

const HeaderBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

});

const CalendarBox = styled(Box)(({ theme }) => ({
  border: "1px solid #9CA4BA66",
  borderRadius: "4px",
  color: "#0D162F",
  fontSize: "12px",
  fontWeight: 500,
  padding: "4px 8px",
  display: "flex",
  gap: "7px",
  alignItems: "center",
  cursor: "pointer",

  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

// const ContentBox = styled(Box)(({ theme }) => ({
//   marginTop: "15px",
//   display: "flex",
//   justifyContent: "space-between",
//   gap: "10px",
//   paddingRight: "0px",
//   flexWrap: "nowrap",

//   [theme.breakpoints.up("xs")]: {
//     flexDirection: "column",
//     paddingRight: "15px",
//   },

//   [theme.breakpoints.up("md")]: {
//     flexDirection: "row",
//     paddingRight: "0px",
//   },
// }));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 500,

  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
  },
}));

const TitleBox = styled(Box)({
  display: "flex",
  gap: "5px",
  alignItems: "center",
});

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: "28px",
  fontWeight: 500,
  color: "#9CA4BA",
  [theme.breakpoints.up("xl")]: {
    fontSize: "40px",
  },
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: "#9CA4BA",

  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const StartBtn = styled("button")({
  width: "max-content",
  textTransform: "capitalize",
  padding: "17px 20px",
  border: "1px solid #009872",
  color: "#ffffff",
  fontWeight: 400,
  background: "#009872ED",
  display: "flex",
  alignItems: "center",
  gap: "25px",
  borderRadius: "4px",
  cursor: "pointer",
  // height: "32px",
  fontSize: "18px",

  "&:hover": {
    background: "#008463",
    border: "1px solid #008463",
    color: "white",
  },

  "&:disabled": {
    border: "1px solid #9CA4BA",
    color: "#9CA4BA",
    // pointerEvents: "none",
  },

  "&:not(:disabled):hover": {
    background: "#008463", // Ensure hover works only if not disabled
    border: "1px solid #008463",
    color: "white",
  },

  "&:disabled:hover": {
    background: "#9CA4BA", // Disabled hover background
    color: "white", // Set text color to white on hover
  },
});

const StartBtnGrey = styled("button")({
  width: "max-content",
  textTransform: "capitalize",
  padding: "6px",
  border: "1px solid #9CA4BA",
  color: "#9CA4BA",
  fontWeight: 400,
  background: "#ffffff",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  borderRadius: "4px",
  cursor: "pointer",
  height: "32px",
  fontSize: "14px",

  "&:hover": {
    background: "#9CA4BA",
    border: "1px solid #9CA4BA",
    color: "white",
  },

  "&:disabled": {
    border: "1px solid #9CA4BA",
    color: "#9CA4BA",
    // pointerEvents: "none",
  },

  "&:not(:disabled):hover": {
    background: "#9CA4BA", // Ensure hover works only if not disabled
    border: "1px solid #9CA4BA",
    color: "white",
  },

  "&:disabled:hover": {
    background: "#9CA4BA", // Disabled hover background
    color: "white", // Set text color to white on hover
  },
});

const Computerimg = styled("img")(({ theme }) => ({
  width: "350px",
  [theme.breakpoints.up("xl")]: {
    width: "527px",
    height: "250px"
  },
}));

const RobotBox = styled(Box)({
  height: "40vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const RobotHeading = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  fontWeight: 500,
  color: "#009872",
  [theme.breakpoints.down("md")]: {
    fontSize: "25px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "64px",
  },
}));

const RobotSubHeading = styled(Typography)(({ theme }) => ({
  fontSize: "25px",
  fontWeight: 500,
  color: "#FF7D34",
  [theme.breakpoints.down("md")]: {
    fontSize: "15px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "36px",
  },
}));

const Robotimg = styled("img")(({ theme }) => ({
  height: "200px",
  [theme.breakpoints.up("xl")]: {
    height: "250px",
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  height: "100%",

  [theme.breakpoints.up("xs")]: {
    flexDirection: "row",
    width: "100%",
  },

  [theme.breakpoints.up("md")]: {
    flexDirection: "column",
    width: "50%",
  },
}));


const ContainerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",

  [theme.breakpoints.up("xs")]: {
    flexWrap: "wrap",
  },
  [theme.breakpoints.up("md")]: {
    flexWrap: "nowrap",
  },
}));

const StatusBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "20px",
  justifyContent: "space-between",


  [theme.breakpoints.up("xs")]: {
    flexWrap: "wrap",
  },
  [theme.breakpoints.up("md")]: {
    flexWrap: "nowrap",
  },
}));

const DBStatusBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#FF7D341A",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  borderRadius: "8px",

  [theme.breakpoints.up("xs")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    width: "31%"
  },
}));

const OutreachBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#5593FE1A",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  borderRadius: "8px",

  [theme.breakpoints.up("xs")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    width: "65%",
  },
}));

const DashboardMainContainer = styled(Box)({
  display: "flex",
  boxSizing: "border-box"
});

const DashboardCard = styled(Box)({
  flex: 1,
  padding: "20px",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: "#ffffff",
  color: "#0D162F",
});

const SdrDashboardCard = styled(Box)(({ theme, backgroundColor }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  backgroundColor: backgroundColor,
  padding: "20px",
  borderRadius: "8px",
}));

const GreetingText = styled(Box)({
  fontSize: "34px",
  color: "#FF7D34",
});

const ActivityCard = styled(Box)(({ theme }) => ({
  backgroundColor: "#ffffff",
  padding: "16px 20px",
  orderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
      gap: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
  },
}));

export {
  DashboardContainer,
  MainBox,
  CardBox,
  CardsContainer,
  ContentBox,
  HeaderBox,
  CalendarBox,
  Title,
  TitleBox,
  Heading,
  SubHeading,
  StartBtn,
  Computerimg,
  RobotBox,
  RobotHeading,
  RobotSubHeading,
  Robotimg,
  CustomBox,
  ContainerBox,
  StatusBox,
  DBStatusBox,
  OutreachBox,
  StartBtnGrey,
  DashboardCard,
  SdrDashboardCard,
  DashboardMainContainer,
  GreetingText,
  ActivityCard
};