import React from 'react';

import { Box, Typography } from '@mui/material';
import {
  PieChart, Pie, Cell, ResponsiveContainer
} from 'recharts';

import { InnerBox, ChartBox, ChartContainer, TitleBox } from '../ActionItStyles';

const data = [
  { name: 'Primary Email', value: 25, color: '#99E3A3' },
  { name: 'Follow-up Email', value: 4, color: '#F8A3A3' },
  { name: 'Call', value: 37, color: '#ADD9FF' },
  { name: 'LinkedIn Message', value: 16, color: '#FFD4A6' },
  { name: 'LinkedIn Inmail', value: 18, color: '#C4B8ED' },
];

const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) / 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="#0D162F"
      textAnchor="middle"
      dominantBaseline="central"
      fontSize={14}
      fontWeight="500"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomLegend = () => {
  const column1 = data.slice(0, 3);
  const column2 = data.slice(3);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
      <Box>
        {column1.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: index < column1.length - 1 ? '10px' : 0,
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: item.color,
                borderRadius: '50%',
                marginRight: '8px',
              }}
            />
            <Typography
              sx={{
                color: '#0D162F',
                fontWeight: '500',
                fontSize: '14px',
              }}
            >
              {item.name}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box>
        {column2.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: index < column2.length - 1 ? '10px' : 0,
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: item.color,
                borderRadius: '50%',
                marginRight: '8px',
              }}
            />
            <Typography
              sx={{
                color: '#0D162F',
                fontWeight: '500',
                fontSize: '14px',
              }}
            >
              {item.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const OutReachStatus = () => {
  return (
    <ChartBox>
      <TitleBox variant="h6" sx={{ marginBottom: "10px" }}>
        Your Outreach Status So far
      </TitleBox>
      <InnerBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 0px 10px #5593FE33',
          flexDirection: {
            xs: "column", gap: "20px",
            sm: "row"
          }
        }}
      >
        <Box sx={{
          display: 'flex', justifyContent: 'space-around',
        }}>
          <ChartContainer>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={data}
                  dataKey="value"
                  nameKey="name"
                  cx="40%"
                  cy="50%"
                  outerRadius={80}
                  innerRadius={50}
                  fill="#8884d8"
                  paddingAngle={2}
                  labelLine={false} // Remove connecting line
                  label={renderCustomLabel} // Use custom label function
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Box>
        <CustomLegend />
      </InnerBox>
    </ChartBox>
  );
};

export default OutReachStatus;