import React, { useEffect, useState } from "react";

import { Table, TableBody, TableRow } from "@mui/material";

import {
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
  InnerBox,
  StyledCheckbox,
  TitleBox,
} from "../ActionItStyles";
import { API } from "../../../global";
import { useToast } from "../../../utils/ToastContext";

const ActivityTable = () => {
  const [activities, setActivities] = useState([]);
  const { showToast } = useToast();
  // const activities = [
  //   { contact: "Cory from Colber Inc.", action: "First Draft Email", status: true },
  //   { contact: "Jordan from Vertex Solutions", action: "First Draft Email", status: false },
  //   { contact: "Jamie from Tech Innovators Inc.", action: "LinkedIn InMail", status: false },
  //   { contact: "Alex from Innovatech Solutions", action: "Call 1", status: false },
  //   { contact: "Jamie from Tech Innovators Inc.", action: "LinkedIn InMail", status: false },
  // ];

  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = () => {
    fetch(`${API}/task-scheduling/scheduled-followups-by-today`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        let activityList = [];
        // eslint-disable-next-line
        res.result.map((data) => {
          const objData = {
            contact: data.contact_person_name,
            action: data.action,
            status: data.status === "pending" ? false : true,
            follow_up_id: data.follow_up_id,
          };
          activityList.push(objData);
        });
        setActivities(activityList);
      })
      .catch((error) => {});
  };

  const onChangeStatus = (e, activity, index) => {
    if (index >= 0 && index < activities.length) {
      activities[index].status = e.target.value;
    } else {
      console.error("Index out of range");
    }

    const statusValue = e.target.checked ? " sent" : "pending";

    fetch(`${API}/task-scheduling/update-scheduled-followups-by-today`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mail_id: activity.follow_up_id,
        status: statusValue,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        showToast("success", "Status updated successfully");
        getActivities();
      })
      .catch((error) => {});
  };

  return (
    <InnerBox
      sx={{
        boxShadow: "0px 0px 10px #5593FE33",
        height: "35%",
      }}
    >
      <TitleBox variant="h6" sx={{ marginBottom: "10px" }}>
        Activities Scheduled For Today
      </TitleBox>
      <StyledTableContainer>
        <Table>
          <StyledTableHead>
            <TableRow>
              <StyledTableCell>Contact</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {activities.length !== 0 ? (
              <>
                {activities.map((activity, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>{activity.contact}</StyledTableCell>
                    <StyledTableCell align="center">
                      {activity.action}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <StyledCheckbox
                        defaultChecked={activity.status}
                        onChange={(e) => onChangeStatus(e, activity, index)}
                      />
                    </StyledTableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <StyledTableCell colSpan={3} align="center">
                  No Data Found!
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </InnerBox>
  );
};

export default ActivityTable;
