import { styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const AccountText = styled(Typography)(({ theme }) => ({
  marginTop: "1.25rem",
  fontSize: "12px",
  fontWeight: "normal",
  color: "#868A97",
  textAlign: "center",
  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
  },
}));

const SignupText = styled("span")(({ theme }) => ({}));

const LinkTag = styled(Link)({
  cursor: "pointer",
  color: "#009872ED",
  textDecoration: "none",

  "&:hover": {
    textDecoration: "underline",
  },
});

const ForgotPasswordLink = styled(Link)(({ theme }) => ({
  textAlign: "right",
  color: "#868A97",
  fontSize: "12px",
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    color: "#009872ED",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
  },
}));

export { AccountText, SignupText, ForgotPasswordLink, LinkTag };
