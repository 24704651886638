import { Box, styled, Typography } from "@mui/material";

const CommonCardBox = styled(Box)(({ width,padding,border }) =>({
  width: width,
  padding: padding,
  border: `1px solid ${border}`,
  borderRadius: "8px",
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: " 0px 0px 6px #FF7D341A",
}));

const CountHeading = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  fontWeight: 500,
  color: "#0D162F",
  [theme.breakpoints.up("xl")]: {
    fontSize: "40px",
  },
}));

const CountSub = styled(Typography)(({ theme, color }) => ({
  fontSize: "10px",
  marginLeft: "20px",
  display: "flex",
  marginBottom: "7px",
  gap: "3px",
  color: color ? "#00BB25" : "#EB0000",
  [theme.breakpoints.up("xl")]: {
    fontSize: "14px",
  },
}));

const Countimg = styled("img")({
  width: "10px",
});

const SubHeadingCount = styled(Box)({
  fontSize: "16px",
  color: "#707070",
});

export { CommonCardBox, CountHeading, CountSub, Countimg, SubHeadingCount };