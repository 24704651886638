import React, { useEffect, useState } from "react";

import NewUserDashboard from "./NewUserDashbaord";
import DashboardPlanIt from "./DashboardPlanIt";

const Dashboard = () => {
  const [showNewUserDashboard, seShowNewUserPassword] = useState("");

  useEffect(() => {
    const chatHistory = localStorage.getItem("chat_history");
    seShowNewUserPassword(chatHistory);
  }, []);

  return (
    <>
      {showNewUserDashboard === "active" ? (
        <DashboardPlanIt />
      ) : (
        <NewUserDashboard />
      )}
    </>
  );
};

export default Dashboard;
