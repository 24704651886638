import { Outlet } from "react-router-dom";

import {
  AuthContainer,
  AuthFormContainer,
  AuthLogo,
  BackgroundImg,
  Heading,
  ImageContainer,
  ImgBox,
  LogoImg,
  SubHeading,
  TextContainer,
} from "./AuthLayoutStyles";
import { authBg, authLogo, LoginLogo } from "../../../Constants/Constant";

const AuthLayout = () => {
  return (
    <AuthContainer>
      <ImageContainer>
        <BackgroundImg src={authBg} alt="" />
        <ImgBox>
          <LogoImg src={authLogo} alt="" />
        </ImgBox>

        <TextContainer>
          <Heading>B2B Lead Generation</Heading>
          <SubHeading>Powered By AI</SubHeading>
        </TextContainer>
      </ImageContainer>
      <AuthFormContainer>
        <AuthLogo src={LoginLogo} alt="" />
        
        <Outlet />
      </AuthFormContainer>
    </AuthContainer>
  );
};

export default AuthLayout;
