// Action Types
export const SET_CHAT_LOADING = "SET_CHAT_LOADING";
export const SET_CHAT_DETAILS_SUCCESS = "SET_CHAT_DETAILS_SUCCESS";
export const SET_CHAT_DETAILS = "SET_CHAT_DETAILS";
export const SET_GEOGRAPHY_DETAILS = "SET_GEOGRAPHY_DETAILS";
export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";
export const GET_AI_ENGINE_LOGS = "GET_AI_ENGINE_LOGS";
export const GET_METRICS_DETAILS = "GET_METRICS_DETAILS";
export const GET_CONVERSATION_DETAILS = "GET_CONVERSATION_DETAILS";
export const SET_CURRENT_QUESTION_DATA = "SET_CURRENT_QUESTION_DATA";

// Action Creators
export const setChatData = (data) => ({
  type: SET_CHAT_DETAILS,
  payload: data,
});

export const setGeographyData = (data) => ({
  type: SET_GEOGRAPHY_DETAILS,
  payload: data,
});


export const setChatLoading = () => ({
  type: SET_CHAT_LOADING,
});

export const getProjectDetailsData = (data) => ({
  type: GET_PROJECT_DETAILS,
  payload: data,
});

export const getAIEngineLogsData = (data) => ({
  type: GET_AI_ENGINE_LOGS,
  payload: data,
});


export const getMetricsDetails = (data) => ({
  type: GET_METRICS_DETAILS,
  payload: data,
});

export const getConversationDetails = (data) => ({
  type: GET_CONVERSATION_DETAILS,
  payload: data,
});


export const setCurrentQuestionData =(data)=>({
  type: SET_CURRENT_QUESTION_DATA,
  payload: data,
})