import React from "react";

import { Box, Typography, IconButton } from "@mui/material";
import { PieChart, Pie, Cell, Label } from "recharts";

import {
  SdrDashboardCard,
  DashboardCard,
  CalendarBox,
} from "../../../components/Dashboard/DashboardStyles";
import { calendar } from "../../../Constants/Constant";

const COLORS = ["#82ca9d", "#ff9999"];

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
  const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      style={{ fontSize: "12px", fontWeight: "bold" }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CatchUp = (props) => {
  const { dashboardData } = props;

  return (
    <SdrDashboardCard flex="0.67" backgroundColor="#EDF4FF">
      <Box display="flex" justifyContent="space-between">
        <Typography sx={{ fontWeight: "medium", fontSize: "22px" }}>
          Catch Up
        </Typography>
        <CalendarBox>
          <IconButton sx={{ p: 0 }}>
            <img src={calendar} alt="calendar" style={{ width: "13px" }} />
          </IconButton>
          <Box>This Week</Box>
        </CalendarBox>
      </Box>
      <Box sx={{
        display: "flex", gap: "20px",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row"
        }
      }}>
        <Box sx={{
          display: "flex", gap: "20px",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "column"
          }
        }}>
          <DashboardCard>
            <Typography sx={{ marginBottom: "10px", fontSize: "20px", fontWeight: "medium" }}>
              Meetings Booked
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {dashboardData?.catchUpData?.meetingsBooked}
            </Typography>
          </DashboardCard>

          <DashboardCard>
            <Typography variant="h6" sx={{ marginBottom: "10px", fontSize: "20px", fontWeight: "medium" }}>
              Companies Reached Out To
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {dashboardData?.catchUpData?.companiesReachedOut}
            </Typography>
          </DashboardCard>
        </Box>

        <DashboardCard>
          <Typography variant="h6" sx={{ marginBottom: "20px", fontSize: "20px" }}>
            Activity Completion Status
          </Typography>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-around" width="100%"
              sx={{
                flexWrap: {
                  xs: "wrap",
                  md: "wrap",
                  lg: "nowrap"
                },
              }}
            >
              {dashboardData?.completionData.map((item, index) => (
                <Box key={index} textAlign="center">
                  <PieChart width={150} height={150}>
                    <Pie
                      data={item.data}
                      cx="50%"
                      cy="50%"
                      innerRadius={40}
                      outerRadius={65}
                      dataKey="value"
                      startAngle={90}
                      endAngle={450}
                      labelLine={false}
                      label={renderCustomizedLabel}
                    >
                      <Label
                        value={item.label}
                        position="center"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      />
                      {item.data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                </Box>
              ))}
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" gap={6} marginTop={5}>
              <Box display="flex" alignItems="center" gap={1}>
                <Box width={12} height={12} bgcolor="#99E3A3" borderRadius="50%" />
                <Typography variant="body2">Completed</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                <Box width={12} height={12} bgcolor="#F8A3A3" borderRadius="50%" />
                <Typography variant="body2">Pending</Typography>
              </Box>
            </Box>
          </Box>
        </DashboardCard>
      </Box>
    </SdrDashboardCard>
  )
}

export default CatchUp