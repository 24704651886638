import React from 'react';

import { Typography } from '@mui/material';

import { InnerBox, OuterBox, TitleBox } from '../ActionItStyles';

const AIEngine = () => {
  return (
    <OuterBox bgColor="#FF7D341A" height="100%">
      <TitleBox variant="h6">
        AI Engine
      </TitleBox>
      <InnerBox sx={{
        fontFamily: 'monospace', overflowY: "auto", height: "100px"
      }}>
        <Typography sx={{ fontSize: "14px", color: "#00A67D" }}>Scanning the list of pending emails</Typography>
        <Typography sx={{ fontSize: "14px", color: "#E76445" }}>Identifying priority contacts...</Typography>
        <Typography sx={{ fontSize: "14px", color: "#00A67D" }}>Scanning the list of pending emails</Typography>
        <Typography sx={{ fontSize: "14px", color: "#E76445" }}>Identifying priority contacts...</Typography>
        <Typography sx={{ fontSize: "14px", color: "#00A67D" }}>Scanning the list of pending emails</Typography>
        <Typography sx={{ fontSize: "14px", color: "#E76445" }}>Identifying priority contacts...</Typography>
      </InnerBox>
    </OuterBox >
  );
};

export default AIEngine;