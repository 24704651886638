import React from "react";

import { Box, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { FlexBox, OutlinedBtn } from "../../../Common/CommonStyles";
import { ActivityCard, SdrDashboardCard } from "../DashboardStyles";
import { useNavigate } from "react-router-dom";

const Activity = (props) => {
  const { dashboardData } = props;
  const navigate = useNavigate()

  const moveToAction=()=>{
    navigate('/piqual-ai/action-it')
  }

  return (
    <SdrDashboardCard flex="0.33" backgroundColor="#FF7D341A">
      <Typography sx={{ fontWeight: "medium", fontSize: "22px" }}>
        Activities Scheduled for Today
      </Typography>
      {dashboardData?.activityData?.map((activity, index) => (
        <ActivityCard key={index}>
          <FlexBox sx={{ gap: "32px" }}>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {activity.count}
            </Typography>
            <Box>
              <Typography sx={{ marginBottom: "2px", fontWeight: "medium", fontSize: "22px" }}>
                {activity.type}
              </Typography>
              <Typography variant="body2" sx={{ color: "#707070" }}>
                {activity.description}
              </Typography>
            </Box>
          </FlexBox>
          <OutlinedBtn
            btnColor="transparent"
            hoverColor="#008463"
            fontSize="14px"
            endIcon={<ArrowForwardIcon />}
            onClick={moveToAction}
          >
            {activity.buttonText}
          </OutlinedBtn>
        </ActivityCard>
      ))}
    </SdrDashboardCard>
  )
}

export default Activity
