// src/ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
// import { getUserRole } from "../../../utils/localStorageUtils";

const ProtectedRoute = ({ element,allowedRoles  }) => {
  const { isAuthenticated } = useAuth();
  //   const userRole = getUserRole();

  if (!isAuthenticated ) {
    return <Navigate to="/" replace />;
  }
  const userRole = localStorage.getItem("role"); // Replace "userRole" with your key
  if (allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate to="/piqual-ai/error" />; // Redirect to unauthorized page
  }
  return element;
};

export default ProtectedRoute;
