import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";

const data = [
  { name: "Positive", value: 25, color: "#99E3A3" },
  { name: "Negative", value: 4, color: "#F8A3A3" },
  { name: "Meetings", value: 37, color: "#ADD9FF" },
  { name: "Dropped", value: 16, color: "#FFD4A6" },
  { name: "Gone Cold", value: 18, color: "#C4B8ED" },
];

// Custom label rendering for the Pie chart
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const adjustedY = y < cy ? y + 10 : y + 0; // Move up or down based on position
  const adjustedX = x < cx ? x + 10 : x + 0; // Adjust horizontal position

  return (
    <text
      x={adjustedX}
      y={adjustedY}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      style={{ fontWeight: "bold", fontSize: "12px" }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

// Custom Legend to display labels with colors
const CustomLegend = ({ payload }) => {
  return (
    <ul style={{ listStyleType: "none", padding: 0 }}>
      {payload.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{
            marginBottom: "20px",
            color: "black",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: entry.color,
              borderRadius: "50%",
              marginRight: "10px",
            }}
          />
          <span>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

const PieChartContainer = () => {
  return (
    <ResponsiveContainer width="100%" height={260}>
      <PieChart>
        <Pie
          data={data}
          cx="40%"
          cy="50%"
          innerRadius={60}
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={1}
          dataKey="value"
          labelLine={false} // Disable connecting lines for labels
          label={renderCustomizedLabel} // Use the custom label renderer
          startAngle={90} // Start from the top
          endAngle={-270} // Go clockwise
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="right"
          wrapperStyle={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          content={<CustomLegend />}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartContainer;
