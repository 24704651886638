import React, { useState } from 'react';

import { Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';

import Header from '../../Reusable/Header/Header';
import ChatInterface from '../PlanIt/ChatInterface';
import { ContentContainer } from '../../Common/CommonStyles';
import AIEngine from './Status/AIEngine';
import OutReachStatus from './Status/OutReachStatus';
import ActivityTable from './Status/ActivityTable';
import { OuterBox, StatusContainer, MainContainer } from './ActionItStyles';

const formatDate = (date) => {
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options).replace(',', '');
};

const ActionIt = () => {
  const navigate = useNavigate();
  const today = new Date();
  const formattedDate = `Action : ${formatDate(today)}`;
  const [headerData, setHeaderData] = useState('');
  const handleBackClick = () => {
    navigate("/piqual-ai/sales-dashboard");
  };

  
  
  const headingData = (data) => {
    setHeaderData(data)
  }

  return (
    <ContentContainer>
      <Header
        title="Action"
        headerData={headerData}
        showSettings={true}
        showTabs={true}
        onBackClick={handleBackClick}
        tabs={[
          { label: formattedDate, value: "new" },
          { label: "All Actions", value: "history" },
        ]}
        actionLabel="New Action"
      />
      <MainContainer>
        <ChatInterface onSendData={headingData}/>
        <StatusContainer>
          <Box sx={{ flex: "0 0 22%", height: "22%",marginTop:"15px" }}>
            <AIEngine />
          </Box>
          <OuterBox
            sx={{
              flex: {
                md: "0 0 73%",
              },
              height: {
                xs: "auto",
                md: "73%"
              }
            }}
            bgColor="#5593FE1A"
          >
            <OutReachStatus />
            <ActivityTable />
          </OuterBox>
        </StatusContainer>
      </MainContainer>
    </ContentContainer>
  );
};

export default ActionIt;