import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";

import {
  dashboard,
  chat,
  mode,
  queries,
  settings,
  Outreach,
  Optimization,
  piqualAI2,
  piqualAI1,
} from "../../Constants/Constant";
import {
  SidebarContainer,
  ArrowIcon,
  SideMenuContainer,
  ProfileName,
  MenuItems,
  MainMenu,
  ItemsList,
  SettingsItem,
  ImgContainer,
  ItemName,
  LogoIcon,
  XsSidebar,
  FullName,
} from "./SidebarStyles";

const Sidebar = (props) => {
  const { sidebarWidth, setSidebarWidth } = props;
  const { firstName, lastName } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [textVisible, setTextVisible] = useState(true);
  const [open, setOpen] = useState(false);
  const role = localStorage.getItem("role");

  const toggleSidebar = () => {
    setTextVisible(false);
    setTimeout(() => {
      setSidebarWidth(isExpanded ? "45px" : "150px");
      setIsExpanded((prev) => !prev);

      // Delay to allow transition before showing text
      setTimeout(() => {
        setTextVisible(true);
      }, 200);
    }, 200);
  };

  const menuItems = [
    {
      icon: dashboard,
      label: "Dashboard",
      path:
        role === "piqai_admin"
          ? "/piqual-ai/dashboard"
          : "/piqual-ai/sdr-dashboard",
      roles: ["piqai_admin", "piqai_sdr"], // Specify roles allowed to see this item
    },
    {
      icon: chat,
      label: "Plan",
      path: "/piqual-ai/plan-it",
      roles: ["piqai_admin"], // Add roles here
    },
    {
      icon: Outreach,
      label: "Action",
      path: "/piqual-ai/action-it",
      roles: ["piqai_sdr"], // Only admins can see this
    },
    {
      icon: Optimization,
      label: "Optimize",
      roles: ["piqai_admin"], // Only SDRs can see this
    },
  ];

  const settingsItems = [
    { icon: mode, label: "Dark Mode" },
    { icon: queries, label: "Help" },
    { icon: settings, label: "Settings" },
  ];

  const stringAvatar = () => {
    let firstNameData = firstName ? firstName?.split(" ")[0][0] : "";
    let lastNameData = lastName ? lastName?.split(" ")[0][0] : "";
    return {
      sx: {
        background: "#FF7D34",
        color: "white",
        width: "31px",
        height: "31px",
        fontSize: "15px",
      },
      children: `${firstNameData}${lastNameData}`,
    };
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleAccountSettings = () => {
    navigate("/piqual-ai/account-settings");
  };

  const filteredMenuItems = menuItems.filter((item) =>
    item.roles.includes(role)
  );

  return (
    <>
      <SidebarContainer sidebarWidth={sidebarWidth}>
        <SideMenuContainer isVisible={textVisible}>
          <Box sx={{ py: 2.5 }}>
            {isExpanded ? (
              <LogoIcon sx={{ mx: 1.5, padding: "4px 10px" }}>
                <img src={piqualAI2} alt="" />
              </LogoIcon>
            ) : (
              <LogoIcon sx={{ mx: 1, padding: "5px" }}>
                <ImgContainer src={piqualAI1} alt="" />
              </LogoIcon>
            )}
          </Box>

          <MenuItems>
            <ItemsList>
              {filteredMenuItems.map((item, index) => (
                <MainMenu
                  key={index}
                  sx={{
                    backgroundColor:
                      location.pathname === item.path
                        ? "#56BB6B"
                        : "transparent",
                    mx: 1,
                  }}
                  onClick={() => navigate(item.path)}
                >
                  <IconButton sx={{ p: 0 }}>
                    <ImgContainer src={item.icon} alt={item.label} />
                  </IconButton>
                  {isExpanded && (
                    <ItemName sx={{ paddingLeft: "10px" }}>
                      {item.label}
                    </ItemName>
                  )}
                </MainMenu>
              ))}
            </ItemsList>

            <ItemsList
              sx={{
                pb: 2.5,
              }}
            >
              {settingsItems.map((item, index) => (
                <SettingsItem key={index}>
                  <IconButton>
                    <ImgContainer src={item.icon} alt={item.label} />
                  </IconButton>
                  {isExpanded && <ItemName>{item.label}</ItemName>}
                </SettingsItem>
              ))}
              <ProfileName onClick={handleAccountSettings}>
                <Avatar {...stringAvatar()} />
                {isExpanded && <FullName>{firstName}</FullName>}
              </ProfileName>
            </ItemsList>
          </MenuItems>
        </SideMenuContainer>

        <ArrowIcon onClick={toggleSidebar} isExpanded={isExpanded}>
          {isExpanded ? (
            <KeyboardArrowLeftIcon sx={{ width: "20px", height: "20px" }} />
          ) : (
            <KeyboardArrowRightIcon sx={{ width: "20px", height: "20px" }} />
          )}
        </ArrowIcon>
      </SidebarContainer>
      {/* <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <Drawer open={open} onClose={toggleDrawer} sx={{width:'150px'}}>
        <List>
          <ListItem button onClick={toggleDrawer}>
            <ListItemText primary="Item 1" />
          </ListItem>
          <ListItem button onClick={toggleDrawer}>
            <ListItemText primary="Item 2" />
          </ListItem>
          <ListItem button onClick={toggleDrawer}>
            <ListItemText primary="Item 3" />
          </ListItem>
        </List>
      </Drawer> */}

      <XsSidebar>
        <IconButton
          sx={{ py: 3 }}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          Drawer
          open={open}
          onClose={toggleDrawer}
          sx={{
            width: 250, // Setting the width here
            flexShrink: 0, // Ensures it doesn't shrink
            "& .MuiDrawer-paper": {
              width: 250, // Also target the Drawer paper component to set width
            },
          }}
          variant="temporary" // Can also be "persistent" depending on your needs
          anchor="left"
        >
          {/* Menu Items */}
          <List>
            {menuItems.map((item, index) => (
              <ListItem button key={index} onClick={toggleDrawer}>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>

          {/* Settings Items */}
          <List>
            {settingsItems.map((item, index) => (
              <ListItem button key={index} onClick={toggleDrawer}>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </XsSidebar>
    </>
  );
};

export default Sidebar;
