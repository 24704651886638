import { useEffect, useState } from "react";

import { Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { plusIconWhite } from '../../Constants/Constant';

import {
  HeaderContainer,
  IconContainer,
  NewTaskBtn,
  SettingBtn,
  StyledButton,
  TabContainer,
  TitleTypography,
} from "./HeaderStyles";
import { useDispatch } from "react-redux";
import { startConversation } from "../../Redux/Actions/projectAction";

const Header = (props) => {

  const dispatch = useDispatch();

  const { title, showSettings, showTabs, onBackClick} = props;
  const [tabName, setTabName] = useState("new");
  const [projectName,setProjectName] = useState('')


  useEffect(()=>{
   const role = localStorage.getItem('role')

   const formattedDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(new Date());

  const actionFormat = `Action: ${formattedDate}`
   if(role === 'piqai_sdr'){
   setProjectName(actionFormat)
   }else{
    setProjectName(props.headerData)

   }
  },[props])

  const handleTabChange = (button) => {
    setTabName(button);
  };

  const handleBackClick = () => {
    onBackClick();
  }

  const createNewProject =()=>{
    setProjectName('')
    dispatch(startConversation({ new_project: true }));
  }

  return (
    <>
      <HeaderContainer>
        <IconContainer>
          <IconButton
            sx={{ p: 0 }}
            onClick={handleBackClick}
          >
            <ArrowBackIcon sx={{ fontSize: "28px" }} />
          </IconButton>
          <TitleTypography variant="h6">{title}</TitleTypography>
        </IconContainer>

        {showTabs && (
          <TabContainer
          //   sx={{
          //     position: "absolute",
          //     left: "51.5%",
          //     transform: "translateX(-50%)",
          //   }}
          >
            <StyledButton
              active={tabName === "new"}
              onClick={() => handleTabChange("new")}
            >
              {projectName ? projectName : "New"}
            </StyledButton>
            <StyledButton
              active={tabName === "history"}
              onClick={() => handleTabChange("history")}
            >
              All Tasks
            </StyledButton>
          </TabContainer>
        )}

        {showSettings && (
          <Box sx={{ display: "flex", gap: "10px" }}>
            <SettingBtn>
              <SettingsOutlinedIcon sx={{ fontSize: "18px" }} />
              <span style={{ fontWeight: "500" }}>Settings</span>
            </SettingBtn>
            <NewTaskBtn onClick={createNewProject}>
              <img src={plusIconWhite} alt="" />
              <span style={{ fontWeight: "500" }}>New Project</span>
            </NewTaskBtn>
          </Box>
        )}
      </HeaderContainer>
    </>
  );
};

export default Header;
