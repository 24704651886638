import { Box, styled, TableCell, TableContainer, TableHead, Checkbox } from '@mui/material';

const TitleBox = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "500",

  [theme.breakpoints.up("xl")]: {
    fontSize: "22px",
  },
}));

const ChartBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',

  [theme.breakpoints.up("xl")]: {
    gap: "20px"
  },
}));

const OuterBox = styled(Box)(({ bgColor, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  backgroundColor: bgColor,
  padding: '20px',
  borderRadius: '8px',
  boxSizing: "border-box",

  [theme.breakpoints.up("xl")]: {
    gap: "20px"
  },
}));

const InnerBox = styled(Box)(({ theme }) => ({
  padding: '12px',
  backgroundColor: "#ffffff",
  borderRadius: "8px"
}));

const StyledTableContainer = styled(TableContainer)({
  borderRadius: "8px",
  border: "1px solid #DDDEE0",
  backgroundColor: "#ffffff",
  overflowY: "auto",
  height: "80%"
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: "#006EED1A",
  border: "1px solid #006EED1A"
});

const StyledTableCellHead = styled(TableCell)({
  fontWeight: "medium",
});

const StyledTableCell = styled(TableCell)({
  padding: "8px",
  fontSize: "14px",
});

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#9CA4BA",
  "&.Mui-checked": {
    color: "#009872",
  },
  width: "16px",
  height: "16px",
  "& svg": {
    fontSize: "16px",
  },
}));

const StatusContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: "100%",
  gap: '10px',
  flex: 0.5,
  [theme.breakpoints.up("md")]: {
    flex: "0 0 48%",
  },
  [theme.breakpoints.up("xl")]: {
    gap: "20px"
  },
}));

const ChartContainer = styled(Box)(({ theme }) => ({
  height: "160px",
  width: "200px",
  [theme.breakpoints.up("xl")]: {
    height: "200px"
  },
}));

const ChatBox = styled(Box)({
  flex: "0 0 48%",
  border: "1px solid #00987233",
  backgroundColor: "#56BB6B0D",
  borderRadius: "8px",
  padding: "20px",
  position: "relative",
});

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  height: "85vh",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column"
  },
}));

const InputContainer = styled(Box)({
  position: "absolute",
  border: "1px solid #C9C9C9",
  backgroundColor: "white",
  borderRadius: "5px",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "90%",
  bottom: "20px"
});

const MessageContainer = styled(Box)({
  minHeight: "80%",
  minWidth: "90%",
  overflowY: "auto",
  whiteSpace: "pre-wrap", // Preserves whitespace and ensures wrapping
  overflowWrap: "break-word", // Allows text to wrap inside the box
  paddingBottom: "10px",
});

export {
  TitleBox,
  OuterBox,
  InnerBox,
  ChartBox,
  StyledTableCell,
  StyledTableCellHead,
  StyledTableContainer,
  StyledTableHead,
  StyledCheckbox,
  StatusContainer,
  ChartContainer,
  ChatBox,
  MainContainer,
  InputContainer,
  MessageContainer
}