import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { Box, Typography } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  AccountBtn,
  AccountInput,
  Asterisk,
  CustomBox,
  SubText,
} from "./ProfilePageStyles";
import { ErrorMsg, PasswordToggleIcon } from "../../Common/CommonStyles";
import { API } from "../../global";
import { useToast } from "../../utils/ToastContext";

const schema = yup.object().shape({
  currentPassword: yup
    .string()
    .required("Current Password is a required field.")
    .min(8, "Password must be at least 8 characters.")
    .max(22, "Password must be at most 22 characters.")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
    .matches(/\d/, "Password must contain at least one numeric digit.")
    .matches(
      /[.#@!%^&*?_()\-+=]/,
      "Password must contain at least one special character (., @, #, !, %, &, *, ?,^, (, ), _, -, =, +)."
    ),
  newPassword: yup
    .string()
    .required("New Password is a required field.")
    .min(8, "Password must be at least 8 characters.")
    .max(22, "Password must be at most 22 characters.")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
    .matches(/\d/, "Password must contain at least one numeric digit.")
    .matches(
      /[.#@!%^&*?_()\-+=]/,
      "Password must contain at least one special character (., @, #, !, %, &, *, ?,^, (, ), _, -, =, +)."
    ),
  reEnterPassword: yup
    .string()
    .required("Confirm Password is a required field.")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match.")
    .required("Password is a required field."),
});

const PasswordContainer = (props) => {
  const { viewProfileDetails } = props;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const currentPassword = watch("currentPassword");
  const newPassword = watch("newPassword");
  const reEnterPassword = watch("reEnterPassword");

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [btnLoading, setBtnLoading] = useState(false);
  const { showToast } = useToast();

  const togglePasswordVisibility = (event) => {
    const { id } = event.currentTarget;
    if (id === "new-password") {
      setShowNewPassword((prevState) => !prevState);
    } else if (id === "confirm-password") {
      setShowConfirmPassword((prevState) => !prevState);
    } else {
      setShowCurrentPassword((prevState) => !prevState);
    }
  };

  const onSubmit = (data) => {
    setBtnLoading(true);
    const Obj = {
      password: data.currentPassword,
      newPassword: data.newPassword,
    };

    fetch(`${API}/auth/change-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(Obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setBtnLoading(false);
        if (response.success) {
          showToast("success", "Password updated successfully");
          viewProfileDetails();
          setValue("currentPassword", "");
          setValue("newPassword", "");
          setValue("reEnterPassword", "");
          setShowCurrentPassword(false);
          setShowConfirmPassword(false);
          setShowNewPassword(false);
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch(() => {
        setBtnLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };

  return (
    <CustomBox>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6">Password</Typography>
        <SubText>
          Update your account password (min 8 characters, include a mix of
          letters & numbers)
        </SubText>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: { xs: "100%", sm: "50%" }, position: "relative" }}>
          <SubText>
            Current Password <Asterisk>*</Asterisk>
          </SubText>
          <AccountInput
            {...register("currentPassword")}
            type={showCurrentPassword ? "text" : "password"}
            placeholder="Enter your current password"
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => e.preventDefault()}
            onCut={(e) => e.preventDefault()}
          />
          <PasswordToggleIcon
            sx={{ top: "1.1em", p: 0 }}
            id="current-password"
            onClick={togglePasswordVisibility}
            disabled={!currentPassword}
          >
            {showCurrentPassword ? (
              <VisibilityOutlinedIcon />
            ) : (
              <VisibilityOffOutlinedIcon />
            )}
          </PasswordToggleIcon>
          <ErrorMsg variant="p">{errors?.currentPassword?.message}</ErrorMsg>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            marginTop: "10px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "50%" }, position: "relative" }}>
            <SubText>
              New Password <Asterisk>*</Asterisk>
            </SubText>
            <AccountInput
              {...register("newPassword")}
              type={showNewPassword ? "text" : "password"}
              placeholder="Enter your new password"
              onCopy={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
            />
            <PasswordToggleIcon
              sx={{ top: "1.1em", p: 0 }}
              id="new-password"
              onClick={togglePasswordVisibility}
              disabled={!newPassword}
            >
              {showNewPassword ? (
                <VisibilityOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </PasswordToggleIcon>
            <ErrorMsg variant="p">{errors?.newPassword?.message}</ErrorMsg>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "50%" }, position: "relative" }}>
            <SubText>
              Re-enter New Password <Asterisk>*</Asterisk>
            </SubText>
            <AccountInput
              {...register("reEnterPassword")}
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Enter your re-enter password"
              onCopy={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
            />
            <PasswordToggleIcon
              sx={{ top: "1.1em", p: 0 }}
              onClick={togglePasswordVisibility}
              id="confirm-password"
              disabled={!reEnterPassword}
            >
              {showConfirmPassword ? (
                <VisibilityOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </PasswordToggleIcon>
            <ErrorMsg variant="p">{errors?.reEnterPassword?.message}</ErrorMsg>
          </Box>
        </Box>
        <AccountBtn
          type="submit"
          loading={btnLoading}
          loadingIndicator="Loading…"
          disabled={!isValid}
          sx={{
            border:
              btnLoading || !isValid
                ? "1px solid lightgrey"
                : "1px solid #009872ED",
            color: "#009872ED",
            "&:hover": {
              bgcolor: "#009872ED",
            },
          }}
        >
          Update
        </AccountBtn>
      </form>
    </CustomBox>
  );
};

export default PasswordContainer;
