import React, { useEffect, useRef, useState } from "react";

import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { buddyWhitebackground, pin, send } from "../../Constants/Constant";
import {
  ChatBox,
  InputContainer,
  MessageContainer,
  // MicButton,
  PinButton,
  SendButton,
  TextContainer,
  InputBox,
  MessageBox,
  MessageView,
  LabelBox,
  LoadingButton,
  // SuggestionButton,
} from "./PlanItStyles";
import { API } from "../../global";
import {
  getAIEngineLogsData,
  getConversationDetails,
  getMetricsDetails,
  getProjectDetailsData,
  setChatData,
  setCurrentQuestionData,
} from "../../Redux/Actions/aiChatActions";
import MinimumDistanceSlider from "./SalesPlan/SliderComponent";
import { FaMicrophone } from "react-icons/fa";
import { startConversation } from "../../Redux/Actions/projectAction";

const ChatInterface = ({ onSendData }) => {
  const dispatch = useDispatch();
  const [inputMessage, setInputMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [loading, setLoading] = useState(false);
  const chatWindowRef = useRef(null);
  const [panelHeading, setPanelHeading] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [conversationDetails, setConversationDetails] = useState(null);
  const userRole = localStorage.getItem("role");

  const projectDataState = useSelector((state) => state.projectDetails);
  const { firstName, lastName } = useSelector((state) => state.user);

  const conversation = useSelector(
    (state) => state.startConversation.projectData
  );

  // const conversationData = projectDataState.conversationDetails;
  const currentQuestionData = projectDataState.currentQuestionData;

  const stringAvatar = () => {
    let firstNameData = firstName ? firstName?.split(" ")[0][0] : "";
    let lastNameData = lastName ? lastName?.split(" ")[0][0] : "";
    return {
      sx: {
        background: "#FF7D34",
        color: "white",
        width: "31px",
        height: "31px",
        fontSize: "15px",
      },
      children: `${firstNameData}${lastNameData}`,
    };
  };

  useEffect(() => {}, [projectDataState]);

  useEffect(() => {
    const chatObj = {
      message: null,
      session_id: null,
      questionOrder: 1,
    };
    if (messageList.length <= 1) {
      localStorage.setItem("inputKey", "none");
    }
    if (!panelHeading) {
      localStorage.setItem("Heading", "");
    }
    // getQuestions(chatObj);
    getProjectId();

    if (messageList.length === 1) {
      fetch(`${API}/project/add-project`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(chatObj),
      })
        .then((response) => response.json())
        .then((res) => {})
        .catch((error) => {});
    }

    // eslint-disable-next-line
  }, []);

  const getDomainName = (url) => {

     // Normalize the URL (handle different formats)
     let domain = url?.replace(/^https?:\/\//, ''); // Remove 'http://' or 'https://'
     domain = domain?.replace(/^www\./, '');        // Remove 'www.'
 
     // Extract company name (first part of the domain)
     const name = domain?.split('.')[0];  // Splitting by '.' and getting the first part
     return name?.charAt(0)?.toUpperCase() + name?.slice(1);  // Capitalize first letter
    
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }

    const chatHeading = messageList[1]?.msg;
    const result = getDomainName(chatHeading);

    if (result) {
      setPanelHeading(result);
      onSendData(result);
    } else {
    }
  }, [messageList, onSendData]);

  const handleInputMessage = () => {
    localStorage.setItem("inputKey", "added");
    if (inputMessage) {
      const obj = {
        msg: inputMessage,
        type: "user",
      };
      setMessageList((prevMessageList) => [...prevMessageList, obj]);
      const chatObj = {
        message: inputMessage,
        question: currentQuestionData.response,
        conversation_code: currentQuestionData.conversation_code,
        project_id: currentQuestionData.project_id,
        question_id: currentQuestionData.question_id,
        question_category: currentQuestionData.question_category,
        needs_confirmation: currentQuestionData.needs_confirmation,
      };
      getQuestions(chatObj);

      getProjectDetails(chatObj.project_id);
    }

    setInputMessage("");
  };

  useEffect(() => {
    if (conversation?.success) {
      setLoading(false);
      localStorage.setItem("loading", false);
      const project = conversation.result;
      setWelcomeMessage(project.response);
      dispatch(getConversationDetails(project));
      if (
        !project.conversation_history ||
        project.conversation_history.length === 0
      ) {
        setConversationDetails(project);
        const chatObj = {
          message: "",
          question: "",
          conversation_code: project?.conversation_code,
          project_id: project?.project_id,
          question_id: "",
        };
        setMessageList([]);
        setLoading(true);
        getQuestions(chatObj);
      } else {
        dispatch(setCurrentQuestionData(project?.conversation_history[0]));
        localStorage.setItem("inputKey", "added");
        let historyList = [];
        project.conversation_history.forEach((data) => {
          const historyObj = {
            msg: data.response,
            type: data.message_from,
          };
          historyList.push(historyObj);
        });
        setMessageList(historyList);
        getProjectDetails(project?.project_id);
      }
    }

    // eslint-disable-next-line
  }, [conversation]);
  const getProjectId = () => {
    dispatch(startConversation({ new_project: false }));

    //   method: "POST",
    //   body: JSON.stringify({ new_project: false }),
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,

    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((res) => {
    //     setLoading(false);
    //     localStorage.setItem("loading", false);
    //     const project = res.result;

    //     setWelcomeMessage(project.response);
    //     dispatch(getConversationDetails(project))

    //     if(!project.conversation_history || project.conversation_history.length === 0){
    //       setConversationDetails(project);
    //       const chatObj = {
    //         message: "",
    //         question: "",
    //         conversation_code: project?.conversation_code,
    //         project_id: project?.project_id,
    //         question_id: "",
    //       };
    //       getQuestions(chatObj);
    //     }else{
    //     dispatch(setCurrentQuestionData(project?.conversation_history[0]))
    //     localStorage.setItem("inputKey", "added");
    //     getProjectDetails()
    //       let historyList = [];
    //       project.conversation_history.forEach((data) => {
    //         const historyObj = {
    //           msg: data.response,
    //           type: data.message_from
    //         };
    //         historyList.push(historyObj);
    //       });
    //       setMessageList(historyList)
    //     }

    //     getAIEngineLog(project.project_id);
    //     getMetricsValue(project.project_id);
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //   });
  };

  const getMetricsValue = (projectIdData) => {
    fetch(`${API}/project/get-project-metrics`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_id: projectIdData }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(getMetricsDetails(res.result));
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAIEngineLog = (projectId) => {
    const projectIdData = projectId
      ? projectId
      : conversationDetails.project_id;
    fetch(`${API}/project/get-ai-action-logs`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_id: projectIdData }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(getAIEngineLogsData(res.result));
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const getProjectDetails = (projectId) => {
    fetch(`${API}/project/get-project-details`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_id: projectId }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          dispatch(getProjectDetailsData(res.result));
          dispatch(setChatData(currentQuestion));
          getAIEngineLog(projectId);
          getMetricsValue(projectId);
        }
      })
      .catch((error) => {
        setLoading(false);
      });

  
  };

  const getQuestions = (chatObj) => {
    setLoading(true);
    localStorage.setItem("loading", true);
    chatObj.role = localStorage.getItem("role");

    fetch(`${API}/chat/conversation`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(chatObj),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        localStorage.setItem("loading", false);
        if (res.success) {
          const question = res.result;
          setCurrentQuestion(question);
          dispatch(setCurrentQuestionData(question));
          const chatResponse = {
            msg: question.response,
            type: question.message_from,
            acknowledgeComment: question.acknowledge_comment,
            answer_suggestions: question.answer_suggestions,
          };

          setMessageList((prevMessageList) => [
            ...prevMessageList,
            chatResponse,
          ]);
          getProjectDetails(chatObj.project_id);
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    localStorage.setItem("inputKey", "added");
    if (event.key === "Enter") {
      setInputMessage(event.target.value);
      handleInputMessage();
    }
  };

  const addRevenueValue = (valueData) => {
    const chatObj = {
      message: valueData,
      session_id: currentQuestion.session_id,
    };
    getQuestions(chatObj);
  };

  const [listening, setListening] = useState(false);

  const handleClick = () => {
    setListening(!listening);
  };

  // const clickSuggestion =(item)=>{
  //    setInputMessage(item);
  //    const chatObj = {
  //     message: item,
  //     question: currentQuestionData.response,
  //     conversation_code: currentQuestionData.conversation_code,
  //     project_id: currentQuestionData.project_id,
  //     question_id: currentQuestionData.question_id,
  //     question_category: currentQuestionData.question_category,
  //     needs_confirmation: currentQuestionData.needs_confirmation,
  //   };
  //   getQuestions(chatObj);

  //   getProjectDetails(chatObj.project_id);
  // }

  return (
    <ChatBox className="chatBox">
      {userRole === "piqai_sdr" ? (
        <Typography
          variant="h6"
          component="div"
          sx={{ fontSize: "18px", marginBottom: "10px" }}
        >
          Action : Hit The Ground Running!
        </Typography>
      ) : (
        <>
          {panelHeading && (
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Project:{panelHeading}
            </Typography>
          )}
        </>
      )}

      <>
        <MessageContainer ref={chatWindowRef}>
          <MessageBox>
            <img src={buddyWhitebackground} alt="" style={{ width: "30px" }} />

            <Box sx={{ mt: 1 }}>
              <LabelBox>Sales Buddy</LabelBox>
              <MessageView>{welcomeMessage}</MessageView>
            </Box>
          </MessageBox>
          {messageList.map((msg, index) => (
            <>
              <MessageBox>
                {msg?.type === "AI" ? (
                  <IconButton sx={{ p: 0 }}>
                    <img
                      src={buddyWhitebackground}
                      alt=""
                      style={{ width: "30px" }}
                    />
                  </IconButton>
                ) : (
                  <Avatar {...stringAvatar()} />
                )}

                <Box sx={{ mt: 1 }}>
                  <LabelBox>
                    {msg?.type === "AI" ? "Sales Buddy" : "You"}
                  </LabelBox>
                  <MessageView>
                    {msg?.acknowledgeComment
                      ? msg.acknowledgeComment
                      : msg?.msg}{" "}
                    {/*msg?.msg*/}
                  </MessageView>
                  {msg?.msg
                    ?.toLowerCase()
                    ?.includes("revenue range".toLowerCase()) && (
                    <MinimumDistanceSlider setRevenueValue={addRevenueValue} />
                  )}
                </Box>
              </MessageBox>
              {/* {msg?.answer_suggestions && (
                <Box sx={{ display: "flex", justifyContent: "center" }} gap={3}>
                  {msg?.answer_suggestions?.split(",")?.map((item) => (
                    <SuggestionButton onClick={()=>clickSuggestion(item)} bgColor="#009872">
                      {item}
                    </SuggestionButton>
                  ))}
                </Box>
              )} */}
            </>
          ))}
          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "5px",
                gap: 1,
              }}
            >
              <LoadingButton sx={{ px: 1 }}>
                <div
                  style={{
                    textAlign: "Center",
                    marginRight: "10px",
                    marginTop: "5px",
                  }}
                >
                  <RotatingLines
                    visible={true}
                    height="15"
                    width="15"
                    color="#009872"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
                Analysing
              </LoadingButton>
            </Box>
          )}
        </MessageContainer>
      </>

      <InputContainer>
        <InputBox>
          {/* <MicButton      listening={listening}
        isClicked={isClicked}
        onClick={handleMicClick}>
            <img src={mic} alt="" style={{ width: "20px" }} />
          </MicButton> */}
          <button
            type="button"
            className={`mic ${listening ? "listening" : ""}`}
            onClick={handleClick}
          >
            <FaMicrophone size={16} />
          </button>
          <PinButton>
            <img src={pin} alt="" style={{ width: "16px" }} />
          </PinButton>
          <TextContainer
            type="text"
            value={inputMessage}
            placeholder="Send a message"
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />{" "}
        </InputBox>
        <SendButton onClick={handleInputMessage}>
          <img src={send} alt="" style={{ width: "16px" }} />
        </SendButton>
      </InputContainer>
    </ChatBox>
  );
};

export default ChatInterface;
